import React from "react";
import { Preferences } from "../../../types/branch-admin-interfaces/ISectionPreference.interface";
import SimpleBar from "simplebar-react";
import { Input, Switch } from "@heathmont/moon-core-tw";

interface AllocatePreferencesProps {
  preferences: Preferences;
  errors: { [key: string]: string };
  onChange: (field: string, value: any) => void;
}

const AllocatePreferences: React.FC<AllocatePreferencesProps> = ({
  preferences,
  errors,
  onChange,
}) => {
  return (
    <div className="bg-[#f5f5f5] py-5 rounded-lg">
      <div className="flex items-center justify-between mb-4 px-4">
        <h3 className="text-lg text-[#272D36] font-semibold">Preferences</h3>
        <div className="flex items-center gap-2">
          <label className="text-sm text-[#272D36] font-semibold">
            Allowed to allocate consecutive shifts
          </label>
          <div className="flex items-center gap-3">
            <Switch
              checked={preferences?.setToAllocateConsecutiveShifts || false}
              onChange={(checked: boolean) =>
                onChange("setToAllocateConsecutiveShifts", checked)
              }
              data-tests="test-id"
              aria-label="Default Switch"
              className="moon-checked:bg-primary"
            />
            <span className="text-sm font-semibold">
              {preferences?.setToAllocateConsecutiveShifts
                ? "Allowed"
                : "Not Allowed"}
            </span>
          </div>
        </div>
      </div>
      <SimpleBar className="h-[284px] overflow-y-auto px-5">
        {/* Working Hours and Weekends Allowed */}
        <div className="grid grid-cols-3 gap-4 items-center mb-4">
          <label className="text-base text-[#272D36] font-semibold">
            Working hours
          </label>
          <div className="col-span-2">
            <div className="w-[100px]">
              <Input
                type="number"
                className="border px-2 py-1 rounded text-[#272D36] text-center"
                value={preferences?.workingHours || ""}
                onChange={(e) =>
                  onChange("workingHours", Number(e.target.value))
                }
              />
              {errors.workingHours && (
                <span className="text-red-500 text-sm">
                  {errors.workingHours}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 items-center gap-4 mb-4">
          <div>
            <label className="text-base text-[#272D36] font-semibold">
              Weekends allowed to work
            </label>
          </div>
          <div className="col=span-2">
            <div className="max-w-[100px]">
              <Input
                type="number"
                className="border px-2 py-1 rounded text-[#272D36] text-center"
                value={preferences?.weekendsAllowedToWork || ""}
                onChange={(e) =>
                  onChange("weekendsAllowedToWork", Number(e.target.value))
                }
              />
            </div>
            {errors.weekendsAllowedToWork && (
              <span className="text-red-500 text-sm">
                {errors.weekendsAllowedToWork}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 items-center gap-4 mb-4">
          <label className="text-sm text-[#272D36] font-semibold">
            Req. Time Off
          </label>
          <div className="col-span-2">
            <div className="flex items-center gap-2">
              <label className="text-sm text-[#272D36]">Week Days</label>
              <div className="max-w-[100px]">
                <Input
                  type="number"
                  className="text-center"
                  value={preferences?.maxROThatCanBeApplied || ""}
                  onChange={(e) =>
                    onChange("maxROThatCanBeApplied", Number(e.target.value))
                  }
                />
              </div>
              <label className="text-sm text-[#272D36]">Weekends</label>
              <div className="max-w-[100px]">
                <Input
                  type="number"
                  className="text-center"
                  value={preferences?.maxROThatCanBeAppliedWeekends || ""}
                  onChange={(e) =>
                    onChange(
                      "maxROThatCanBeAppliedWeekends",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
            </div>
            {errors.maxROThatCanBeApplied && (
              <span className="text-red-500 text-sm">
                {errors.maxROThatCanBeApplied}
              </span>
            )}
            {errors.maxROThatCanBeAppliedWeekends && (
              <span className="text-red-500 text-sm">
                {errors.maxROThatCanBeAppliedWeekends}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 items-center gap-4 mb-4">
          <label className="text-sm text-[#272D36] font-semibold">
            Override Time-off requests
          </label>
          <div className="col-span-2">
            <div className="flex items-center gap-2">
              <div className="max-w-[70px]">
                <Input
                  type="number"
                  className="text-center px-0"
                  value={preferences?.overrideTimeOfRequests || ""}
                  onChange={(e) =>
                    onChange("overrideTimeOfRequests", Number(e.target.value))
                  }
                />
              </div>
              %
            </div>
            {errors.overrideTimeOfRequests && (
              <span className="text-red-500 text-sm">
                {errors.overrideTimeOfRequests}
              </span>
            )}
          </div>
        </div>

        {/* Number of consecutive shifts */}
        <div className="grid grid-cols-3 items-center gap-4 mb-4">
          <label className="text-sm text-[#272D36] font-semibold">
            Number of consecutive shifts
          </label>
          <div className="col-span-2">
            <div className="flex items-center gap-2">
              <label className="text-sm text-[#272D36]">Day shift</label>
              <div className="max-w-[100px]">
                <Input
                  type="number"
                  className="text-center"
                  value={preferences?.consecutiveDayShifts || ""}
                  onChange={(e) =>
                    onChange("consecutiveDayShifts", Number(e.target.value))
                  }
                />
              </div>
              <label className="text-sm text-[#272D36]">Night shift</label>
              <div className="max-w-[100px]">
                <Input
                  type="number"
                  className="text-center"
                  value={preferences?.consecutiveNightShifts || ""}
                  onChange={(e) =>
                    onChange("consecutiveNightShifts", Number(e.target.value))
                  }
                />
              </div>
              <label className="text-sm text-[#272D36]">Overall shift</label>
              <div className="max-w-[100px]">
                <Input
                  type="number"
                  className="text-center"
                  value={preferences?.consecutiveShifts || ""}
                  onChange={(e) =>
                    onChange("consecutiveShifts", Number(e.target.value))
                  }
                />
              </div>
            </div>
            {errors.consecutiveDayShifts && (
              <span className="text-red-500 text-sm">
                {errors.consecutiveDayShifts}
              </span>
            )}
            {errors.consecutiveNightShifts && (
              <span className="text-red-500 text-sm">
                {errors.consecutiveNightShifts}
              </span>
            )}
            {errors.consecutiveShifts && (
              <span className="text-red-500 text-sm">
                {errors.consecutiveShifts}
              </span>
            )}
          </div>
        </div>

        {/* Shift Duration and Allocate Consecutive Shifts */}
        <div className="grid grid-cols-3 gap-4 mb-4">
          <label className="text-sm text-[#272D36] font-semibold">
            Shift duration
          </label>
          <div className="col-span-2">
            <div className="max-w-[100px]">
              <Input
                type="number"
                className="text-center"
                value={preferences?.shiftDuration || ""}
                onChange={(e) =>
                  onChange("shiftDuration", Number(e.target.value))
                }
              />
            </div>
            {errors.shiftDuration && (
              <span className="text-red-500 text-sm">
                {errors.shiftDuration}
              </span>
            )}
          </div>
        </div>

        {/* Forecast Patient Utilization */}
        <div className="grid grid-cols-3 gap-4 mb-4">
          <label className="text-sm text-[#272D36] font-semibold">
            Forecast patient utilization
          </label>
          <div className="col-span-2 ">
            <div className="flex items-center gap-2">
              <div className="max-w-[70px]">
                <Input
                  type="number"
                  className="text-center px-0"
                  value={preferences?.patientForecastUtilization || ""}
                  onChange={(e) =>
                    onChange(
                      "patientForecastUtilization",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
              %
            </div>
            {errors.patientForecastUtilization && (
              <span className="text-red-500 text-sm">
                {errors.patientForecastUtilization}
              </span>
            )}
          </div>
        </div>

        {/* Shifts Distribution */}
        <div className="grid grid-cols-3 mb-4">
          <label className="text-sm text-[#272D36] font-semibold">
            Shifts Distribution
          </label>
          <div className="col-span-2">
            <div className="flex items-center gap-3">
              <label className="text-sm text-[#272D36]">Day shift</label>
              <div className="max-w-[100px]">
                <Input
                  type="number"
                  className="text-center"
                  value={preferences?.shiftDistributionDayShifts || ""}
                  onChange={(e) =>
                    onChange(
                      "shiftDistributionDayShifts",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
              %<label className="text-sm text-[#272D36]">Night shift</label>
              <div className="max-w-[100px]">
                <Input
                  type="number"
                  className="text-center"
                  value={preferences?.shiftDistributionNightShifts || ""}
                  onChange={(e) =>
                    onChange(
                      "shiftDistributionNightShifts",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
              %
            </div>
            {errors.shiftDistribution && (
              <span className="text-red-500 text-sm">
                {errors.shiftDistribution}
              </span>
            )}
            {errors.shiftDistributionDayShifts && (
              <span className="text-red-500 text-sm">
                {errors.shiftDistributionDayShifts}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 mb-4">
          <label className="text-sm text-[#272D36] font-semibold">
            Shifts Start Time
          </label>
          <div className="col-span-2">
            <div className="flex items-center gap-3">
              <label className="text-sm text-[#272D36]">Day shift</label>
              <div className="flex-[0_0_150px]">
                <Input
                  type="time"
                  className="text-center"
                  value={preferences?.shiftStartTime?.day || ""}
                  onChange={(e) =>
                    onChange("shiftStartTime.day", e.target.value)
                  }
                />
              </div>
              <label className="text-sm text-[#272D36]">Night shift</label>
              <div className="flex-[0_0_150px]">
                <Input
                  type="time"
                  className="text-center"
                  value={preferences?.shiftStartTime?.night || ""}
                  onChange={(e) =>
                    onChange("shiftStartTime.night", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 mb-4">
          <label className="text-sm text-[#272D36] font-semibold">
            Mid Day Shift
          </label>
          <div className="col-span-2">
            <div className="flex items-center gap-3">
              <label className="text-sm text-[#272D36]">Start Time</label>
              <div className="flex-[0_0_150px]">
                <Input
                  type="time"
                  className="prefrenceInput max-w-[150px_!important]"
                  value={preferences?.midShiftStartTime?.day || ""}
                  onChange={(e) =>
                    onChange("midShiftStartTime.day", e.target.value)
                  }
                />
              </div>
              <label className="text-sm text-[#272D36]">Shift Duration</label>
              <div className="max-w-[100px]">
                <Input
                  type="number"
                  className="text-center"
                  min={8}
                  max={12}
                  value={preferences?.midShiftStartTime?.dayDuration || ""}
                  onChange={(e) =>
                    onChange(
                      "midShiftStartTime.dayDuration",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
            </div>
            {errors.midDayShift && (
              <span className="text-red-500 text-sm">{errors.midDayShift}</span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3">
          <label className="text-sm text-[#272D36] font-semibold">
            Mid Night Shift
          </label>
          <div className="col-span-2">
            <div className="flex items-center gap-3">
              <label className="text-sm text-[#272D36]">Start Time</label>
              <div className="flex-[0_0_150px]">
                <Input
                  type="time"
                  className="text-center"
                  value={preferences?.midShiftStartTime?.night || ""}
                  onChange={(e) =>
                    onChange("midShiftStartTime.night", e.target.value)
                  }
                />
              </div>
              <label className="text-sm text-[#272D36]">Shift Duration</label>
              <div className="max-w-[100px]">
                <Input
                  type="number"
                  min={8}
                  max={12}
                  className="text-center"
                  value={preferences?.midShiftStartTime?.nightDuration || ""}
                  onChange={(e) =>
                    onChange(
                      "midShiftStartTime.nightDuration",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
            </div>
            {errors.midNightShift && (
              <span className="text-red-500 text-sm">
                {errors.midNightShift}
              </span>
            )}
          </div>
        </div>
      </SimpleBar>
    </div>
  );
};

export default AllocatePreferences;
