import React, { useEffect, useState } from "react";
import FilterShifts from "../UI/FilterShifts";
import FilterWeekMonth from "../UI/FilterWeekMonth";
import MonthlySchedule from "../Calendar/monthly/MonthlySchedule";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button } from "@heathmont/moon-core-tw";
import { ISection } from "../../../types/branch-admin-interfaces/ISection.interface";
import { ISectionInfo } from "../../../types/nurse-manager-interfaces/ISectionInfo.interface";
import { ScheduleRecordCardData } from "../../../types/nurse-manager-interfaces/IScheduleHistory.interface";
import RegenerateScheduleModal from "../autoPreferences/RegenerateScheduleModal";
import {
  setSnackBar,
  setToastMessage,
} from "../../../features/ToastSlice/toastSlice";
import { IScheduleData } from "../../../types/nurse-manager-interfaces/IAutoScheduleRes.interface";
import { IMonthYear } from "../../../types/nurse-manager-interfaces/IMonthYear.interface";
import { ISchedule } from "../../../types/nurse-manager-interfaces/IMonthCalendar.interface";
import {
  createAutoSchedule,
  getSchedule,
  submitSchedule,
} from "../../../services/nurse-manager-services/nursemanager.service";

// const months: any = Array.from({ length: 12 }, (_, index) => ({
//   name: `${moment().month(index).format("MMMM")}`,
//   monthName: moment().month(index).format("MMMM"),
//   label: index + 1,
// }));

// const years: any = Array.from({ length: 15 }, (_, index) => ({
//   name: currentYear - index,
//   year: currentYear - index,
//   label: "year",
// }));

interface Props {
  sectionInfo?: ISectionInfo;
  setControlView?: (data: boolean) => void;
  controlView?: boolean;
  responseOfScheduleRequest?: (data: any) => void;
  selectedSection?: ISection;
  selectedRecord?: ScheduleRecordCardData;
  selectedScheduleId?: string;
  setSelectedScheduleId?: (id: string) => void;
  setIsShowSchedule?: (check: boolean) => void;
}
const MonthlyScheduleCalendar: React.FC<Props> = ({
  sectionInfo,
  setControlView,
  controlView,
  responseOfScheduleRequest,
  selectedSection,
  selectedRecord,
  setSelectedScheduleId,
  setIsShowSchedule,
}) => {
  const navigate = useNavigate();
  const User = useSelector((state: RootState) => state.auth.User);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [isScheduleAvailable, setIsScheduleAvailable] = useState(false);
  const [isRegenerateOpen, setIsRegenerateOpen] = useState(false);
  const [month, setMonth] = useState({
    name: "",
    monthName: "",
    label: -1,
  });

  const isNurseDirector =
    User?.role === "nurse director" && isScheduleAvailable;

  const [year, setYear] = useState({
    name: "",
    year: "",
    label: "",
  });

  useEffect(() => {
    if (!selectedRecord) return;
    setMonth({
      name: moment()
        ?.month((selectedRecord?.month as number) - 1)
        ?.format("MMMM"),
      monthName: moment()
        ?.month((selectedRecord?.month as number) - 1)
        ?.format("MMMM"),
      label: moment()
        .month(selectedRecord?.month as number)
        .get("M"),
    });
    setYear({
      name: moment()
        ?.year(selectedRecord?.year as number)
        ?.format("YYYY"),
      year: moment()
        ?.year(selectedRecord?.year as number)
        ?.format("YYYY"),
      label: "year",
    });
  }, [selectedRecord]);

  useEffect(() => {
    if (!sectionInfo) return;
    setMonth({
      name: `${moment()
        ?.month(sectionInfo?.month - 1)
        ?.format("MMMM")}`,
      monthName: `${moment()
        ?.month(sectionInfo?.month - 1)
        ?.format("MMMM")}`,
      label: moment()?.month(sectionInfo?.month)?.get("M"),
    });
    setYear({
      name: `${moment()
        ?.year(sectionInfo?.year as number)
        ?.format("YYYY")}`,
      year: `${moment()
        ?.year(sectionInfo?.year as number)
        ?.format("YYYY")}`,
      label: "year",
    });
  }, [sectionInfo]);

  const makeAutoSchedule = async (
    scheduleId?: string,
    monthYear?: IMonthYear
  ) => {
    const monthSelected = Number(
      monthYear?.monthSelected?.label ?? month?.label
    );
    const yearSelected = monthYear?.yearSelected?.year ?? year?.year;
    const day = monthYear?.dayStart ?? 1;
    const dayEnd =
      monthYear?.dayEnd ??
      moment()
        .month(monthSelected - 1)
        .daysInMonth();
    const res: any = await createAutoSchedule(
      day as number,
      dayEnd as number,
      monthSelected as number,
      +yearSelected,
      scheduleId
    );
    if (res?.res) {
      setFetchAgain((prev) => !prev);
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(
        setToastMessage("Preferences of this section are not found")
      );
    }
    console.log("error: ", res?.err);
  };

  const submitForSchedule = async (draftId?: string) => {
    const res = await submitSchedule(selectedRecord?._id, draftId);
    if (res?.res) {
      setFetchAgain(!fetchAgain);
      setIsShowSchedule && setIsShowSchedule(false);
      navigate("/");
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Schedule submitted successfully"));
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(
        setToastMessage("Schedule already exists for the selected month")
      );
    }
  };

  return (
    <>
      {(User?.role === "nurse manager" ||
        User?.temporaryRole === "nurse manager" ||
        isNurseDirector) && (
        <div className="py-[10px] sm:px-7 px-4 bg-gohan shadow-moon-lg relative">
          <div className="flex gap-[10px] items-center w-full">
            <div className={`flex-1 flex gap-[10px] `}>
              {isScheduleAvailable ? (
                // <div className="flex items-center gap-[10px]">
                <>
                  <div>
                    <FilterShifts />
                  </div>
                  <div>
                    <FilterWeekMonth />
                  </div>
                </>
              ) : (
                // </div>
                ""
              )}
            </div>
            {sectionInfo ? (
              <div className="flex items-center gap-5">
                {sectionInfo?.status !== "accepted" &&
                sectionInfo?.status !== "declined" ? (
                  <div className="flex items-center gap-[10px]">
                    <Button
                      variant="secondary"
                      // size="sm"
                      // className="min-h-[42px]"
                      onClick={() => {
                        responseOfScheduleRequest &&
                          responseOfScheduleRequest({
                            sectionId: sectionInfo?._id,
                            status: "declined",
                          });
                        setFetchAgain(true);
                        setControlView && setControlView(!controlView);
                      }}
                    >
                      Decline
                    </Button>
                    <Button
                      variant="tertiary"
                      // size="sm"
                      // className="min-h-[42px]"
                      onClick={() => {
                        responseOfScheduleRequest &&
                          responseOfScheduleRequest({
                            sectionId: sectionInfo?._id,
                            status: "accepted",
                          });
                        setControlView && setControlView(!controlView);
                        setFetchAgain(true);
                      }}
                    >
                      Accept
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                <Button
                  variant="secondary"
                  // size="sm"
                  // className="min-h-[42px]"
                  onClick={() => setControlView && setControlView(!controlView)}
                >
                  Back to Scheduler
                </Button>
              </div>
            ) : (
              ""
            )}
            {User?.role !== "nurse director" ? (
              <>
                {/* <div className="max-w-[173px] w-full">
                    <DropDown
                      field={"monthName"}
                      value={month}
                      options={months}
                      setOptions={setMonth}
                    />
                  </div>
                  <div className="max-w-[173px] w-full">
                    <DropDown
                      field={"name"}
                      value={year}
                      options={years}
                      setOptions={setYear}
                    />
                  </div> */}
                <div className="flex items-center gap-4">
                  {selectedRecord?.status === "draft" ? (
                    <Button
                      className="min-h-[42px] bg-[rgb(36,156,186,0.15)] text-hit"
                      onClick={() => {
                        submitForSchedule();
                      }}
                    >
                      Submit Draft Schedule
                    </Button>
                  ) : (
                    <div className="text-moon-14 gap-2">
                      <span className=" min-h-[42px]  text-[#8697A2]">
                        Status:{" "}
                      </span>
                      <span>{selectedRecord?.status?.toUpperCase()}</span>
                    </div>
                  )}
                  <Button
                    className="min-h-[42px] bg-[rgb(36,156,186,0.15)] text-hit"
                    onClick={() => {
                      setIsRegenerateOpen(true);
                    }}
                  >
                    Regenerate Schedule
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      navigate("/scheduler", {
                        state: {
                          scheduleId: selectedRecord?._id,
                          month: selectedRecord?.month,
                          year: selectedRecord?.year,
                        },
                      });
                    }}
                  >
                    Update Schedule
                  </Button>
                  <Button
                    // className="min-h-[42px]"
                    variant="secondary"
                    onClick={() => {
                      setIsShowSchedule && setIsShowSchedule(false);
                      navigate("/");
                    }}
                  >
                    Back to Records
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      <div className="bg-white">
        <MonthlySchedule
          sectionInfo={sectionInfo as ISectionInfo}
          selectedScheduleId={selectedRecord?._id}
          setSelectedScheduleId={setSelectedScheduleId}
          fetchAgain={fetchAgain}
          isScheduleAvailable={isScheduleAvailable}
          setIsScheduleAvailable={setIsScheduleAvailable}
          selectedSection={selectedSection}
          month={month}
          year={year}
          selectedRecord={selectedRecord as ScheduleRecordCardData}
        />
      </div>
      <RegenerateScheduleModal
        isOpen={isRegenerateOpen}
        setIsOpen={setIsRegenerateOpen}
        makeAutoSchedule={makeAutoSchedule}
        scheduleId={selectedRecord?._id as string}
        month={month}
        // month={month}
        setMonth={setMonth}
        year={year}
        // year={year}
        setYear={setYear}
      />
    </>
  );
};

export default MonthlyScheduleCalendar;
