import React, { useState, useMemo } from "react";
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  CellContext,
  createColumnHelper,
} from "@tanstack/react-table";
import {
  GenericEdit,
  GenericDelete,
  GenericInfo,
  ControlsChevronRightSmall,
  ControlsChevronLeftSmall,
} from "@heathmont/moon-icons-tw";
import { Chip, IconButton } from "@heathmont/moon-core-tw";
import { Tooltip } from "react-tooltip";
import { IPatientJob } from "../../../types/operation-manager-interfaces/IPatientJob.interface";
import {
  ILeavesTable,
  IPatientTable,
} from "../../../types/operation-manager-interfaces/IJobHistroy.interface";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJobHistory } from "../../../services/operation-manager-services/job.service";
import { setHistoryDetails } from "../../../features/history/history.slice";
import { ILeaves } from "../../../types/branch-admin-interfaces/ILeaves.interface";

interface TableProps {
  data: ILeavesTable;
  onDelete: (data: ILeaves) => void;
}

const LeaveDetailTable: React.FC<TableProps> = ({ data, onDelete }) => {
  const rowsPerPage = 10;

  const columnHelper = createColumnHelper<ILeaves>();
  const dispatch = useDispatch();
  const { id } = useParams();

  const getHistory = async (page: number = 1) => {
    const res: any = await fetchJobHistory(id || "", page);

    if (res.res) {
      dispatch(setHistoryDetails(res?.res));
    }
  };

  const CellRenderer = React.useCallback(
    (info: CellContext<ILeaves, unknown>, fieldName: string) => {
      const error = info.row.original.validationErrors?.find(
        (error) => error.field === fieldName
      );

      const tooltipId = `tooltip-${info.row.id}-${fieldName}`;

      return (
        <div className="flex items-center space-x-2">
          {fieldName === "employeeSection" ? (
            <span className={`text-gray-400`}>
              {info.row.original.sectionDetails?._id
                ? info.row.original.sectionDetails?.sectionName
                : ""}
            </span>
          ) : (
            <span className="text-gray-400  capitalize">
              {info.getValue<string>()}
            </span>
          )}

          {error && (
            <>
              <a
                data-tooltip-id={tooltipId}
                data-tooltip-content={`Error in ${fieldName}: ${error.message}`}
              >
                <IconButton
                  variant="ghost"
                  size="sm"
                  icon={<GenericInfo />}
                  className="text-red-500"
                />
              </a>
              <Tooltip
                place="bottom"
                id={tooltipId}
                style={{ backgroundColor: "#fff", color: "red" }}
              />
            </>
          )}
        </div>
      );
    },
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("employeeId", {
        header: () => <span>ID</span>,
        cell: (info) => CellRenderer(info, "patientId"),
      }),
      columnHelper.accessor("employeeName", {
        id: "patientName",
        header: () => <span>Employee Name</span>,
        cell: (info) => CellRenderer(info, "patientName"),
      }),
      columnHelper.accessor("from.date", {
        enableSorting: false,
        header: () => "From (Date)",
        cell: (info) => CellRenderer(info, "from.date"),
      }),
      columnHelper.accessor("to.date", {
        enableSorting: false,
        header: () => "To (Date)",
        cell: (info) => CellRenderer(info, "dischargeDate"),
      }),
      columnHelper.accessor("employeeSection", {
        enableSorting: false,
        header: "Employee Section",
        cell: (info) => CellRenderer(info, "employeeSection"),
      }),
      columnHelper.accessor("leaveType", {
        enableSorting: false,
        header: "Leave Type",
        cell: (info) => CellRenderer(info, "leaveType"),
      }),
      columnHelper.accessor("status", {
        enableSorting: false,
        header: "Status",
        cell: (info) => CellRenderer(info, "status"),
      }),
      columnHelper.accessor((row) => row, {
        enableSorting: false,
        header: "Action",
        cell: (info) => (
          <div className="flex space-x-2">
            <IconButton
              variant="ghost"
              icon={<GenericDelete />}
              size="sm"
              className="bg-red-50 text-red-500"
              onClick={() => onDelete(info.row.original)}
            />
          </div>
        ),
      }),
    ],
    [CellRenderer, onDelete]
  );

  const handleNext = React.useCallback(() => {
    if (data.currentPage < data.totalPages) {
      getHistory(data.currentPage + 1);
    }
  }, [data.currentPage, data.totalPages]);

  const handlePrevious = React.useCallback(() => {
    if (data.currentPage > 1) {
      getHistory(data.currentPage - 1);
    }
  }, [data.currentPage]);

  const handlePageClick = React.useCallback((page: number) => {
    getHistory(page);
  }, []);

  const table = useReactTable({
    data: data.items,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="overflow-auto bg-white max-h-[calc(100vh-300px)]">
        <table className="min-w-full ">
          <thead className="bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-4 py-2 text-left  bg-slate-200  capitalize font-semibold text-xs h-12"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="px-4 py-2 border border-gray-300 text-sm text-[#8697A2]"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-end p-4 px-6 gap-4">
        <div className="text-sm">
          Showing {rowsPerPage * (data.currentPage - 1) + 1} -{" "}
          {Math.min(rowsPerPage * data.currentPage, data.totalItems)} of{" "}
          {data.totalItems}
        </div>
        <div className="flex items-center space-x-2">
          <IconButton
            icon={<ControlsChevronLeftSmall />}
            variant="secondary"
            size="sm"
            onClick={handlePrevious}
            disabled={data.currentPage === 1}
          />
          {Array.from({ length: data.totalPages }).map((_, index) => (
            <button
              key={index}
              className={`w-8 h-8 rounded ${
                data.currentPage === index + 1
                  ? "bg-primary text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => handlePageClick(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <IconButton
            icon={<ControlsChevronRightSmall />}
            variant="secondary"
            size="sm"
            onClick={handleNext}
            disabled={data.currentPage === data.totalPages}
          />
        </div>
      </div>
    </>
  );
};

const getStatusColor = (status: string) => {
  switch (status) {
    case "New":
      return "bg-green-100 text-green-600";
    case "Existing":
      return "bg-yellow-100 text-yellow-600";
    default:
      return "bg-gray-100 text-gray-600";
  }
};

export default LeaveDetailTable;
