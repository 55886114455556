import { useSearchParams } from "react-router-dom";
import { IManualSchedulingSubmit } from "../../types/nurse-manager-interfaces/IManualSchedulingSubmit.interface";
import { api } from "../../utils/axios";
import { scheduled } from "rxjs";
// {{healthCareDev}}/nurseManagerDashboard/getEmployeeScheduleWeek/10/2023/1
export const getSchedulesHistory = async (page: number) => {
  try {
    const { data, ok } = await api.get(`/schedule/getSchedulesHistory`, {
      page,
      limit: 12,
    });
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
interface IEmployeeView {
  month: string | number;
  year: string | number;
  A_Z?: boolean;
  Z_A?: boolean;
  available?: boolean;
  notAvailable?: boolean;
  Nurse?: boolean;
  HCA?: boolean;
  seniorNurse?: boolean;
  search?: string;
  nurseManager?: boolean;
}
export const getEmployeesWeeklySchedule = async (
  month: number,
  year: number,
  week: number,
  sectionId: string,
  scheduleId?: string
) => {
  // console.log("month: ",month,"year: ",year,"week: ",week);
  try {
    const { data, ok } = await api.get(
      `/nurseManagerDashboard/getEmployeeScheduleWeek/${month}/${year}/${week}/${sectionId}?${
        scheduleId ? "&scheduleId=" + scheduleId : ""
      }`
    );
    // console.log(`/nurseManagerDashboard/getEmployeeScheduleWeek/${month}/${year}/${week}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getEmployeesMonthlySchedule = async (
  month: number,
  year: number,
  sectionId: string,
  scheduleId: string
) => {
  try {
    const { data, ok } = await api.get(
      `/nurseManagerDashboard/getEmployeeScheduleMonth/${month}/${year}/${sectionId}?${
        scheduleId ? "&scheduleId=" + scheduleId : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const createAutoSchedule = async (
  day: number,
  toDay: number,
  month: number,
  year: number,
  scheduleId?: string
) => {
  try {
    const { data, ok } = await api.post(
      `/schedule/createSchedule/${month}/${year}/${day}/${toDay}${
        scheduleId ? "?scheduleId=" + scheduleId : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
// {{healthcareLocal}}/schedule/getSchedule/10/2023
export const getSchedule = async (
  month?: number,
  year?: number,
  scheduleId?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/schedule/getSchedule/${month}/${year}${
        scheduleId ? "?scheduleId=" + scheduleId : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
// {{healthcareLocal}}/schedule/getEmployeesForManualScheduling?day=30&month=10&year=2023&shift=day
export const getEmployeesForManualScheduling = async (
  scheduleId?: string,
  day?: number | string,
  shift?: string,
  draftId?: string,
  employeeId?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/schedule/getEmployeesForManualScheduling?${
        scheduleId ? "&scheduleId=" + scheduleId : ""
      }${day ? "&day=" + day : ""}${shift ? "&shift=" + shift : ""}${
        draftId ? "&draftId=" + draftId : ""
      }${employeeId ? "&employeeId=" + employeeId : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
// export const createManualSchedule = async (
//   month: number | string,
//   year: number | string,
//   day?: number | string,
//   shift?: string,
//   tempScheduleId?: string,
//   draftId?: string
// ) => {
//   try {
//     const { data, ok } = await api.get(
//       `/schedule/createScheduleManual/?month=${month}&year=${year}${
//         day ? "&day=" + day : ""
//       }${shift ? "&shift=" + shift : ""}${
//         tempScheduleId ? "&tempScheduleId=" + tempScheduleId : ""
//       }${draftId ? "&draftId=" + draftId : ""}`
//     );
//     if (ok) {
//       return { res: data, err: null };
//     }
//   } catch (error) {
//     return { res: null, err: error };
//   }
// };

export const submitSchedule = async (scheduleId?: string, draftId?: string) => {
  try {
    const { data, ok } = await api.post(
      `/schedule/submitSchedule?scheduleId=${draftId ? draftId : scheduleId}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

// ${
//   day ? "&day=" + day : ""
// }
// {{healthcareLocal}}/nurseManagerDashboard/getEmployeeViewForNurseManager/10/2023

export const getEmployeeViewForNurseManager = async (params: IEmployeeView) => {
  const {
    month,
    year,
    A_Z,
    Z_A,
    available,
    notAvailable,
    Nurse,
    HCA,
    seniorNurse,
    nurseManager,
    search,
  } = params;
  console.log("Params", params);
  const queryParams = new URLSearchParams({
    "A-Z": A_Z !== undefined ? A_Z.toString() : "",
    "Z-A": Z_A !== undefined ? Z_A.toString() : "",
    Available: available !== undefined ? available.toString() : "",
    "Not Available": notAvailable !== undefined ? notAvailable.toString() : "",
    Nurse: Nurse !== undefined ? Nurse.toString() : "",
    HCA: HCA !== undefined ? HCA.toString() : "",
    "Senior Nurse": seniorNurse !== undefined ? seniorNurse.toString() : "",
    "Nurse Manager": nurseManager !== undefined ? nurseManager.toString() : "",
    Search: search || "",
  });
  const filteredEntries = Array.from(queryParams.entries()).filter(
    ([key, value]) => value !== ""
  );

  // Create a new URLSearchParams object with non-empty entries
  const filteredQueryParams = new URLSearchParams(filteredEntries);

  try {
    const { data, ok } = await api.get(
      `/nurseManagerDashboard/getEmployeeViewForNurseManager/${month}/${year}?${filteredQueryParams}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const submitManualSchedule = async (args: IManualSchedulingSubmit) => {
  try {
    const params = args?.params;
    const reqBody = args?.reqBody;
    const { data, ok } = await api.post(
      `/schedule/createScheduleManual/?day=${params?.date}&month=${
        params?.month
      }&year=${params?.year}&shift=${params?.shift}${
        params?.tempScheduleId
          ? "&tempScheduleId=" + params?.tempScheduleId
          : params?.scheduleId
          ? "&scheduleId=" + params?.scheduleId
          : ""
      }
          `,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    } else {
      return { res: null, err: data as any };
    }
  } catch (error) {
    return { res: null, err: error as any };
  }
};
// /nurseManagerDashboard/getScheduleByDay/1/10/2023?dayShift=true&nightShift=true&All=true&Senior Nurse=true&Nurse=true&HCA=true
interface IGetScheduleByDayReq {
  day: string | number;
  month: string | number;
  year: string | number;
  scheduleId: string;
  dayShift?: boolean;
  nightShift?: boolean;
  All?: boolean;
  Senior_Nurse?: boolean;
  Nurse?: boolean;
  HCA?: boolean;
}
export const getScheduleByDay = async (args: IGetScheduleByDayReq) => {
  try {
    const { data, ok } = await api.get(
      `/nurseManagerDashboard/getScheduleByDay/${args?.day}/${args?.month}/${
        args?.year
      }?${args?.scheduleId && "scheduleId=" + args?.scheduleId}${
        args?.dayShift
          ? "&dayShift=" + args?.dayShift
          : args?.nightShift
          ? "&nightShift=" + args?.nightShift
          : null
      }${
        args?.All
          ? "&All=" + args?.All
          : args?.Senior_Nurse
          ? "&Senior Nurse=" + args?.Senior_Nurse
          : args?.HCA
          ? "&HCA=" + args?.HCA
          : args?.Nurse
          ? "&Nurse=" + args?.Nurse
          : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

// {{healthcareLocal}}/nurseManagerDashboard/getRequestsOfEmployees/10/2023?page=2&pageSize=15
interface IRequestOfView {
  month: string | number;
  year: string | number;
  page: string | number;
  pageSize: string | number;
  A_Z?: boolean;
  Z_A?: boolean;
  Applied?: boolean;
  Pending?: boolean;
  Rejected?: boolean;
  Accepted?: boolean;
  Latest_First?: boolean;
  Oldest_First?: boolean;
  RO: boolean;
  Swap: boolean;
  search?: string;
}

export const getRequestsOfEmployees = async (params: IRequestOfView) => {
  const {
    month,
    year,
    page,
    pageSize,
    A_Z,
    Z_A,
    Applied,
    Pending,
    Rejected,
    Accepted,
    Latest_First,
    Oldest_First,
    RO,
    Swap,
    search,
  } = params;
  console.log("Params", params);
  const queryParams = new URLSearchParams({
    page: page !== undefined ? page.toString() : "",
    pageSize: pageSize !== undefined ? pageSize.toString() : "",
    "A-Z": A_Z !== undefined ? A_Z.toString() : "",
    "Z-A": Z_A !== undefined ? Z_A.toString() : "",
    Applied: Applied !== undefined ? Applied.toString() : "",
    Pending: Pending !== undefined ? Pending.toString() : "",
    Rejected: Rejected !== undefined ? Rejected.toString() : "",
    Accepted: Accepted !== undefined ? Accepted.toString() : "",
    "Latest First": Latest_First !== undefined ? Latest_First.toString() : "",
    "Oldest First": Oldest_First !== undefined ? Oldest_First.toString() : "",
    RO: RO !== undefined ? RO.toString() : "",
    Swap: Swap !== undefined ? Swap.toString() : "",
    Search: search || "",
  });
  const filteredEntries = Array.from(queryParams.entries()).filter(
    ([key, value]) => value !== ""
  );

  // Create a new URLSearchParams object with non-empty entries
  const filteredQueryParams = new URLSearchParams(filteredEntries);

  try {
    const { data, ok } = await api.get(
      `/nurseManagerDashboard/getRequestsOfEmployees/${month}/${year}?${filteredQueryParams}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

// {{healthcareLocal}}/nurseManagerDashboard/getSpecificEmployeeRequests/10/2023/64bf88c1c523ab2594b7f3ac
export const getSpecificEmployeeRequests = async (
  month: number | string,
  year: number | string,
  id: number | string
) => {
  try {
    const { data, ok } = await api.get(
      `/nurseManagerDashboard/getSpecificEmployeeRequests/${month}/${year}/${id}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getPreferencesAndRules = async () => {
  try {
    const { data, ok } = await api.get(`/section/getPreferencesAndRules`);
    if (ok) {
      return data;
    }
  } catch (error) {
    return { err: error };
  }
};

export const deleteSchedules = async (scheduleId: string) => {
  try {
    const { data, ok } = await api.delete(
      `/schedule/deleteSchedule/${scheduleId}`
    );
    if (ok) {
      return data;
    }
  } catch (error) {
    return { err: error };
  }
};

// {{healthcareLocal}}/nurseManagerDashboard/getEmployeeScheduleMonth/10/2023
// export const setHospital = async (params: IHospitalInfo) => {
//   try {
//     console.log("Params", params);
//     const response = await api.post(`hospital/addHospital`, params, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     return response;
//   } catch (error) {
//     return { err: error };
//   }
// };

// export const getAllHospitalsWithBranches = async () => {
//   try {
//     const { data, ok } = await api.get(`hospital/getAllHospitalsWithBranches`);
//     if (ok) {
//       return data;
//     }
//   } catch (error) {
//     return { err: error };
//   }
// };

// export const editHospital = async (hospitalId: any, param: IHospitalInfo) => {
//   try {
//     const { data, ok } = await api.post(
//       `hospital/editHospital/${hospitalId}`,
//       param,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       },
//     );
//     if (ok) {
//       return data;
//     }
//   } catch (error) {
//     return { err: error };
//   }
// };

// export const deleteHospitals = async (hospitalId: any) => {
//   try {
//     const { data, ok } = await api.delete(
//       `hospital/deleteHospital/${hospitalId}`,
//     );
//     if (ok) {
//       return data;
//     }
//   } catch (error) {
//     return { err: error };
//   }
// };

// https://dev.api.healthcare.aquila360.com/hospital/editHospital/:hospitalId

export const getSwapRequests = async (params: any) => {
  try {
    const { data, ok } = await api.get(`/swapRequest?limit=10`, params);
    if (ok) {
      return data;
    }
  } catch (error) {
    return { err: error };
  }
};

export const addLeaveRequest = async (params: any) => {
  try {
    const { data, ok, originalError } = await api.post(
      `employee/createLeave`,
      params
    );
    if (ok) {
      return { res: data, err: null };
    } else {
      return { res: null, err: data as any };
    }
  } catch (error) {
    return { err: error };
  }
};
