import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import FooterCalendar from "./FooterCalendar/FooterCalendar";
import MonthDays from "../TopbarCalendar/MonthDays";
import MonthDate from "../TopbarCalendar/MonthDate";
import TopbarCalendar from "../TopbarCalendar/TopbarCalendar";
import EmployeeShiftMonthly from "./EmployeeShiftMonthly";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../../store/store";
import {
  addLeaveRequest,
  getEmployeesForManualScheduling,
  getEmployeesMonthlySchedule,
  submitManualSchedule,
} from "../../../../services/nurse-manager-services/nursemanager.service";
import {
  IEmployeeData,
  IEmployeeDataResponse,
} from "../../../../types/nurse-manager-interfaces/IEmployeeMonthlyScheduleResponse.interface";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../types/IDateDropDown.interface";
import { ISectionInfo } from "../../../../types/nurse-manager-interfaces/ISectionInfo.interface";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../features/ToastSlice/toastSlice";
import { ScheduleRecordCardData } from "../../../../types/nurse-manager-interfaces/IScheduleHistory.interface";
import moment from "moment";
import ShiftDialog from "./ShiftDialog";
import { IManualSchedulingRes } from "../../../../types/nurse-manager-interfaces/IManualSchedulingRes.interface";
import toast from "react-hot-toast";
interface Props {
  sectionInfo: ISectionInfo;
  isScheduleAvailable: boolean;
  setIsScheduleAvailable: (scheduleCheck: boolean) => void;
  fetchAgain?: boolean;
  selectedSection?: any;
  month?: IMonthDropDown;
  year?: IYearDropDown;
  selectedScheduleId?: string;
  setSelectedScheduleId?: (id: string) => void;
  selectedRecord: ScheduleRecordCardData;
}

interface SelectedShift {
  date: number;
  employee: IManualSchedulingRes | undefined;
}

const POSITION_PRIORITY: Record<string, number> = {
  "senior nurse": 1,
  nurse: 2,
  HCA: 3,
};

const MonthlySchedule: React.FC<Props> = ({
  sectionInfo,
  fetchAgain,
  setIsScheduleAvailable,
  selectedSection,
  month,
  year,
  selectedScheduleId,
  setSelectedScheduleId,
  selectedRecord,
}) => {
  const { User } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [hours, setHours] = useState("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<SelectedShift | null>(
    null
  );
  const [selectedRole, setSelectedRole] = useState("All");
  const [employeeData, setEmployeeData] = useState<IEmployeeDataResponse>(
    {} as IEmployeeDataResponse
  );
  const [selectedType, setSelectedType] = useState<string | null>(null);

  const employeesMonthlySchedule = async () => {
    const currentDate = moment();
    const data: any = await getEmployeesMonthlySchedule(
      sectionInfo ? sectionInfo?.month : ((currentDate.month() + 1) as number),
      sectionInfo ? sectionInfo?.year : (currentDate.year() as number),
      User?.assignedSection !== "All"
        ? (User?.assignedSection as string)
        : sectionInfo !== undefined
        ? sectionInfo?.section
        : selectedSection?._id,
      sectionInfo?._id ?? selectedRecord?._id
    );
    if (data?.res) {
      data?.res?.response?.length === 0
        ? setIsScheduleAvailable(false)
        : setIsScheduleAvailable(true);
      setEmployeeData(data?.res);
      return;
    }
    // store.dispatch(setSnackBar("error"));
    // store.dispatch(setToastMessage(data?.res?.message));
  };
  useEffect(() => {
    if (
      (!User?.assignedSection || !month?.monthName || !year?.name) &&
      User?.role !== "nurse director"
    ) {
      return;
    }
    if (User?.role === "nurse director") {
      employeesMonthlySchedule();
    } else if (
      User?.role === "nurse manager" ||
      User?.temporaryRole === "nurse manager"
    ) {
      setSelectedScheduleId && setSelectedScheduleId("");
      employeesMonthlySchedule();
    }
  }, [User, month, year, selectedSection]);

  useEffect(() => {
    if (sectionInfo || fetchAgain) {
      employeesMonthlySchedule();
    }
  }, [sectionInfo, fetchAgain]);

  const handleSelectShift = async (date: number, employee: string) => {
    const data = await getEmployeesForManualScheduling(
      selectedRecord._id,
      undefined,
      undefined,
      undefined,
      employee
    );
    if (data?.res) {
      setSelectedShift({
        date,
        employee: data.res as IManualSchedulingRes,
      });
      openModal();
    }
    // handleSubmitSchedule(date, employee);
  };

  const handleAddLeave = async (date: string, leaveType: string) => {
    const shiftDate = moment(new Date(date));
    const employee = selectedShift?.employee?.employees[0];
    const data = {
      employeeId: employee?.employeeId,
      employeeName: employee?.employeeName,
      leaveType,
      from: {
        date: shiftDate.format("MM/DD/yyyy"),
        dayNumber: shiftDate.date(),
        month: shiftDate.month() + 1,
        year: shiftDate.year(),
      },
      to: {
        date: shiftDate.format("MM/DD/yyyy"),
        dayNumber: shiftDate.date(),
        month: shiftDate.month() + 1,
        year: shiftDate.year(),
      },
    };
    const response = await addLeaveRequest(data);
    if (response?.res) {
      closeModal();
      setIsChecked(false);
      setHours("");
      await employeesMonthlySchedule();
    } else {
      closeModal();
      dispatch(setSnackBar("error"));
      dispatch(setToastMessage(response?.err.message));
    }
  };

  const handleSubmitSchedule = async (
    shift: "day" | "night" | "mid-day" | "mid-night" | "",
    isRemove?: boolean,
    isOnLeave?: boolean,
    cancelLeave?: boolean,
    leaveType?: string
  ) => {
    const currentDate = moment();

    const shifts = employeeData.employeeData
      .filter((emp) =>
        emp.shifts.some(
          (sft) =>
            sft.dayNumber === selectedShift?.date &&
            (shift === "day"
              ? sft.isDayShift
              : shift === "night"
              ? sft.isNightShift
              : shift === "mid-day"
              ? sft.isMidDayShift
              : shift === "mid-night"
              ? sft.isMidNightShift
              : false)
        )
      )
      .map((emp) => emp._id);
    const employees = isRemove
      ? shifts.filter(
          (sft) => sft !== selectedShift?.employee?.employees[0]._id
        )
      : [...shifts, selectedShift?.employee?.employees[0]._id || ""];
    const res = await submitManualSchedule({
      params: {
        month: (currentDate.month() + 1).toString(),
        year: currentDate.year().toString(),
        date: selectedShift?.date.toString(),
        scheduleId: selectedRecord._id,
        shift,
      },
      reqBody: {
        employees,
        employee: selectedShift?.employee?.employees[0]._id,
        isOnLeave,
        cancelLeave,
        leaveType,
      },
    });
    if (res?.res) {
      closeModal();
      setIsChecked(false);
      setHours("");
      await employeesMonthlySchedule();
    } else {
      closeModal();
      dispatch(setSnackBar("error"));
      dispatch(setToastMessage(res.err.message));
    }
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const closeModal = () => {
    setIsOpenModal(false);
    setIsChecked(false);
    setSelectedType(null);
  };
  const openModal = () => setIsOpenModal(true);

  return (
    <>
      {employeeData?.employeeData ? (
        <>
          <div className="flex flex-wrap">
            <div className="w-1/6">
              <TopbarCalendar
                month={month?.monthName as string}
                year={year?.year as string}
              />
            </div>
            <div className="w-5/6">
              <MonthDate
                month={employeeData?.employeeData as IEmployeeData[]}
                monthDetail={month as IMonthDropDown}
                year={year as IYearDropDown}
                scheduleId={selectedScheduleId || ""}
              />
            </div>
          </div>
          <div className="flex bg-beerus">
            <div className="w-1/6">
              <div className="h-[64px] 2xl:px-[30px] px-3 pe-[14px] py-2  border-r border-sky2  flex items-center justify-between">
                <h3 className="2xl:text-moon-20 text-moon-16 font-medium transition-colors">
                  Employee
                </h3>
                <h3 className="2xl:text-moon-16 text-moon-12  font-medium text-gray-500">
                  Total Hours
                </h3>
              </div>
            </div>
            <div className="w-5/6">
              <MonthDays
                month={month?.label as number}
                year={year?.year as string}
              />
            </div>
          </div>

          {/* <SimpleBar className="h-[calc(100vh-553px)]  overflow-y-auto">  for no notification line*/}
          <SimpleBar className="h-[calc(100vh-395px)] overflow-x-auto">
            <EmployeeShiftMonthly
              data={
                employeeData?.employeeData
                  ?.filter((emp) =>
                    selectedRole !== "All" ? emp.role === selectedRole : true
                  )
                  ?.sort((a, b) => {
                    const aPriority = POSITION_PRIORITY[a.role] ?? Infinity; // Handle unknown positions
                    const bPriority = POSITION_PRIORITY[b.role] ?? Infinity;
                    return aPriority - bPriority; // Lower priority values come first
                  }) as IEmployeeData[]
              }
              month={month?.label as number}
              year={year?.year as string}
              onDateSelected={handleSelectShift}
            />
          </SimpleBar>
          <FooterCalendar setSelected={setSelectedRole} />
        </>
      ) : (
        <div className="forapprovel flex items-center text-center justify-center flex-col">
          <img
            src={"images/no-schedule-for-month.svg"}
            alt="NO SCHEDULE AVAILABLE"
          />
          <p className="font-bold mt-5">
            {month?.name + " " + year?.year}{" "}
            <span className="font-semibold text-trunks">
              schedule not found
            </span>
          </p>
        </div>
      )}
      <ShiftDialog
        employee={selectedShift?.employee}
        isOpenModal={isOpenModal}
        shiftDate={selectedShift?.date || 0}
        data={employeeData.employeeData}
        handleSubmit={handleSubmitSchedule}
        closeModal={closeModal}
        hours={hours}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        setHours={setHours}
        handleAddLeave={handleAddLeave}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
    </>
  );
};

export default MonthlySchedule;
