import { ISectionRules } from "../../types/branch-admin-interfaces/IRulesForm.interface";
import { api } from "../../utils/axios";
export const getSections = async () => {
  try {
    const { data, ok } = await api.get(`/section/getSectionsBranchAdmin`);
    if (ok) {
      return data;
    }
    throw new Error("Invalid Response");
  } catch (error) {
    return { err: error };
  }
};

export const getSectionsPref = async (sectionId: any) => {
  try {
    const { data, ok } = await api.get(
      `/section/getSectionPreferences/${sectionId}`
    );

    if (ok) {
      return data;
    }
    throw new Error("Invalid Response");
  } catch (error) {
    return { err: error };
  }
};
export const getSectionsRules = async (sectionId: any) => {
  try {
    const { data, ok } = await api.get(`/section/getSectionRules/${sectionId}`);

    if (ok) {
      return data;
    }
    throw new Error("Invalid Response");
  } catch (error) {
    return { err: error };
  }
};

export const setSectionsPref = async (
  preferencesPayload: any,
  applyToAll?: boolean
) => {
  try {
    const { data, ok } = await api.post(
      `section/setSectionPreferences${applyToAll ? "?applyToAll=true" : ""}`,
      preferencesPayload
    );

    if (ok) {
      return { dataUpdate: true, data: data };
    }
  } catch (error) {
    return { err: error };
  }
};
export const setSectionsRules = async (rulesPayload: ISectionRules) => {
  try {
    const { data, ok } = await api.post(
      `/section/setSectionRules`,
      rulesPayload
    );

    if (ok) {
      return { dataUpdate: true, data: data };
    }
  } catch (error) {
    return { err: error };
  }
};

export const editSectionBranchAdmin = async (body: any) => {
  try {
    const { data, ok } = await api.put(
      `/section/editSectionBranchAdmin/${body?._id}`,
      {
        sectionName: body?.sectionName,
      }
    );
    if (ok) {
      return data;
    }
  } catch (err) {
    return { err: err };
  }
  // return api.put(`/section/editSection`,section)
};

export const getSectionsBranchAdmin = async () => {
  try {
    const { data, ok } = await api.get(`/section/getSectionsListBranchAdmin/`);
    if (ok) {
      return data;
    }
  } catch (err) {
    return { err: err };
  }
  // return api.put(`/section/editSection`,section)
};
export const addSectionBranchAdmin = async (sections: any) => {
  try {
    const { data, ok } = await api.post(`/section/addSectionBranchAdmin/`, {
      sections,
    });
    if (ok) {
      return data;
    }
  } catch (err) {
    return { err: err };
  }
  // return api.put(`/section/editSection`,section)
};
