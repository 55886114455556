import React, { useEffect, useState } from "react";
import {
  IRulesForm,
  ISectionRules,
  Rules,
} from "../../../../types/branch-admin-interfaces/IRulesForm.interface";
interface Props {
  sectionId: string;
  sectionName: string;
  rulesFormData: IRulesForm;
  setSectionRules: (data: ISectionRules) => void;
  validationErrors: any;
  onValidationErrors: (errors: any) => void;
}
const RulesForm: React.FC<Props> = ({
  sectionId,
  rulesFormData,
  sectionName,
  setSectionRules,
  validationErrors,
  onValidationErrors,
}) => {
  const [sectionRules, setSectionsRules] = useState<ISectionRules>({
    sectionId: sectionId,
    rules: rulesFormData?.sectionRule?.rules as Rules,
  });
  // const validateForm = () => {
  //   const errors: any = {};

  //   // Check numberOfRooms
  //   if (
  //     //   (
  //     sectionRules?.rules?.numberOfRooms !== null &&
  //     sectionRules?.rules?.numberOfRooms < 1
  //     ||  sectionRules?.rules?.numberOfRooms !== null  && sectionRules?.rules?.numberOfRooms > 10)
  //    {
  //     errors.numberOfRooms = "There should be at least 1 room and it's should not be more than 10";
  //   }

  //   if (
  //     //   (
  //     sectionRules?.rules?.numberOfBeds !== null &&
  //     sectionRules?.rules?.numberOfBeds < 1
  //     // ||sectionRules?.sectionPreference?.preferences?.consecutiveShifts > 4)
  //   ) {
  //     errors.numberOfBeds = "There should be at least 1 bed";
  //   }

  //   if (
  //     sectionRules?.rules?.numberOfBedsPerNursesDayShift !== null &&
  //     sectionRules?.rules?.numberOfBedsPerNursesNightShift !== null &&
  //     (
  //       (sectionRules?.rules?.numberOfBedsPerNursesDayShift < 1 ||
  //       sectionRules?.rules?.numberOfBedsPerNursesDayShift > 10)||
  //      ( sectionRules?.rules?.numberOfBedsPerNursesNightShift < 1 ||
  //       sectionRules?.rules?.numberOfBedsPerNursesNightShift > 10)
  //     )
  //   ) {
  //     errors.numberOfBedsPerNurses =
  //       "Number of beds per nurse should be between 1 and 10 in each shift";
  //   }

  //   // Check numberOfBedsPerHCADayShift and numberOfBedsPerHCANightShift
  //   if (
  //     sectionRules?.rules?.numberOfBedsPerHCADayShift !== null &&
  //     sectionRules?.rules?.numberOfBedsPerHCANightShift !== null &&
  //     (
  //       sectionRules?.rules?.numberOfBedsPerHCADayShift < 1 ||
  //       sectionRules?.rules?.numberOfBedsPerHCADayShift > 10 ||
  //       sectionRules?.rules?.numberOfBedsPerHCANightShift < 1 ||
  //       sectionRules?.rules?.numberOfBedsPerHCANightShift > 10
  //     )
  //   ) {
  //     errors.numberOfBedsPerHCA =
  //       "Number of beds per HCA should be between 1 and 10 in each shift";
  //   }

  //   // Check numberOfBedsPerSeniorNurses
  //   if (
  //     sectionRules?.rules?.numberOfBedsPerSeniorNurses !== null &&
  //     (
  //       sectionRules?.rules?.numberOfBedsPerSeniorNurses < 1 ||
  //       sectionRules?.rules?.numberOfBedsPerSeniorNurses > 10
  //     )
  //   ) {
  //     errors.numberOfBedsPerSeniorNurses =
  //       "Number of beds per senior nurse should be between 1 and 10";
  //   }

  //   // Check numberOfCriticalPatientsPerHCA
  //   if (
  //     sectionRules?.rules?.numberOfCriticalPatientsPerHCA !== null &&
  //     (
  //       sectionRules?.rules?.numberOfCriticalPatientsPerHCA < 1 ||
  //       sectionRules?.rules?.numberOfCriticalPatientsPerHCA > 10
  //     )
  //   ) {
  //     errors.numberOfCriticalPatientsPerHCA =
  //       "Number of critical patients per HCA should be between 1 and 10";
  //   }

  //   // Check numberOfCriticalPatientsPerNurses
  //   if (
  //     sectionRules?.rules?.numberOfCriticalPatientsPerNurses !== null &&
  //     (
  //       sectionRules?.rules?.numberOfCriticalPatientsPerNurses < 1 ||
  //       sectionRules?.rules?.numberOfCriticalPatientsPerNurses > 10
  //     )
  //   ) {
  //     errors.numberOfCriticalPatientsPerNurses =
  //       "Number of critical patients per nurse should be between 1 and 10";
  //   }

  //   // Other validation checks ...
  //   onValidationErrors(errors);
  //   return Object.keys(errors).length === 0;
  // };

  const validateForm = () => {
    const errors: any = {};

    // Extract the rules for easier access
    const rules: Rules = sectionRules?.rules;

    // Helper function for range validation
    const isValidRange = (value: number | null, min: number, max: number) =>
      value && (value < min || value > max);

    // Check numberOfRooms
    if (isValidRange(rules?.numberOfRooms, 1, 50)) {
      errors.numberOfRooms =
        "There should be at least 1 room and it should not be more than 50";
    }

    // Check numberOfBeds
    if (isValidRange(rules?.numberOfBeds, 1, 50)) {
      errors.numberOfBeds =
        "There should be at least 1 bed and it should not be more than 50";
    }

    // Check numberOfBedsPerNursesDayShift and numberOfBedsPerNursesNightShift
    if (
      isValidRange(rules?.numberOfBedsPerNursesDayShift, 1, 10) ||
      isValidRange(rules?.numberOfBedsPerNursesNightShift, 1, 10)
    ) {
      errors.numberOfBedsPerNurses =
        "Number of beds per nurse should be between 1 and 10 in each shift";
    }

    // Check numberOfBedsPerHCADayShift and numberOfBedsPerHCANightShift
    if (
      isValidRange(rules?.numberOfBedsPerHCADayShift, 1, 15) ||
      isValidRange(rules?.numberOfBedsPerHCANightShift, 1, 15)
    ) {
      errors.numberOfBedsPerHCA =
        "Number of beds per HCA should be between 1 and 15 in each shift";
    }

    // Check numberOfBedsPerSeniorNurses
    if (isValidRange(rules?.numberOfBedsPerSeniorNurses, 0, 10)) {
      errors.numberOfBedsPerSeniorNurses =
        "Number of beds per senior nurse should be between 1 and 10";
    }

    // Check numberOfCriticalPatientsPerHCA
    if (
      (sectionName.toLowerCase().includes("intensive care") ||
        sectionName.toLowerCase().includes("icu")) &&
      isValidRange(rules?.numberOfCriticalPatientsPerHCA, 1, 10)
    ) {
      errors.numberOfCriticalPatientsPerHCA =
        "Number of critical patients per HCA should be between 1 and 10";
    }

    // Check numberOfCriticalPatientsPerNurses
    if (
      (sectionName.toLowerCase().includes("intensive care") ||
        sectionName.toLowerCase().includes("icu")) &&
      isValidRange(rules?.numberOfCriticalPatientsPerNurses, 1, 10)
    ) {
      errors.numberOfCriticalPatientsPerNurses =
        "Number of critical patients per nurse should be between 1 and 10";
    }

    // Other validation checks ...

    onValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (!rulesFormData) return;
    setSectionsRules((prev) => ({
      ...prev,
      rules: rulesFormData?.sectionRule?.rules as Rules,
    }));
  }, [rulesFormData]);

  //   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     if (!sectionRules?.rules) return;
  //     console.log("eve: ", event?.target?.name);
  //     const tempData = { ...sectionRules?.rules };

  //     // tempData[event.target.name] = event.target.value as number;

  //     setSectionsRules((prevData) => ({
  //       ...prevData,
  //       rules: tempData,
  //     }));

  //     onValidationErrors({
  //       ...validationErrors,
  //       [event.target.name]: undefined,
  //     });
  //   };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sectionRules?.rules) return;

    const tempData = { ...sectionRules.rules };

    // Ensure that the value is parsed as a number
    const value = parseInt(event.target.value, 10);
    const errors = { ...validationErrors };

    setSectionsRules((prevData) => ({
      ...prevData,
      rules: {
        ...tempData,
        [event.target.name]: value,
      },
    }));

    onValidationErrors({
      ...errors,
      [event.target.name]: undefined,
    });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    validateForm();
  };
  useEffect(() => {
    const dataToPost: ISectionRules = {
      sectionId: sectionId,
      rules: sectionRules?.rules as Rules,
    };
    setSectionRules(dataToPost);
    validateForm();
  }, [sectionRules]);

  return (
    <>
      <div>
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Number of beds in section <span className="text-red-500">*</span>
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Define in this field the number of beds in a section
            </p>
          </div>
          <div className="">
            <input
              type="number"
              placeholder="0"
              value={
                sectionRules?.rules?.numberOfBeds === null
                  ? ""
                  : sectionRules?.rules?.numberOfBeds
              }
              name="numberOfBeds"
              onChange={handleInputChange}
              onBlur={handleBlur}
              className={`prefrenceInput ${
                validationErrors.numberOfBeds ? "border-red-500" : ""
              }`}
            />
            {validationErrors.numberOfBeds && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.numberOfBeds}
              </div>
            )}
          </div>
        </div>

        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Number of rooms in section <span className="text-red-500">*</span>
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Define in this field the number of rooms in a section
            </p>
          </div>
          <div className="">
            <input
              type="number"
              placeholder="0"
              value={
                sectionRules?.rules?.numberOfRooms === null
                  ? ""
                  : sectionRules?.rules?.numberOfRooms
              }
              name="numberOfRooms"
              onChange={handleInputChange}
              onBlur={handleBlur}
              className={`prefrenceInput ${
                validationErrors.numberOfRooms ? "border-red-500" : ""
              }`}
            />
            {validationErrors.numberOfRooms && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.numberOfRooms}
              </div>
            )}
          </div>
        </div>

        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Number of patients per HCA <span className="text-red-500">*</span>
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Define the number of patients assigned to an HCA
            </p>
          </div>
          <div className="col-span-2 ">
            <div className="flex flex-wrap gap-[10px]">
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  value={
                    sectionRules?.rules?.numberOfBedsPerHCADayShift === null
                      ? ""
                      : sectionRules?.rules?.numberOfBedsPerHCADayShift
                  }
                  name="numberOfBedsPerHCADayShift"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className={`prefrenceInput ${
                    validationErrors.numberOfBedsPerHCADayShift
                      ? "border-red-500"
                      : ""
                  }`}
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  Day shifts
                </label>
              </div>
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  value={
                    sectionRules?.rules?.numberOfBedsPerHCANightShift === null
                      ? ""
                      : sectionRules?.rules?.numberOfBedsPerHCANightShift
                  }
                  name="numberOfBedsPerHCANightShift"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className={`prefrenceInput ${
                    validationErrors.numberOfBedsPerHCANightShift
                      ? "border-red-500"
                      : ""
                  }`}
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  Night shifts
                </label>
              </div>
            </div>
            {validationErrors.numberOfBedsPerHCA && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.numberOfBedsPerHCA}
              </div>
            )}
          </div>
        </div>

        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Number of patients per Nurse{" "}
              <span className="text-red-500">*</span>
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Define the number of patients assigned to a nurse
            </p>
          </div>
          <div className="col-span-2 ">
            <div className="flex flex-wrap  gap-[10px]">
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  value={
                    sectionRules?.rules?.numberOfBedsPerNursesDayShift === null
                      ? ""
                      : sectionRules?.rules?.numberOfBedsPerNursesDayShift
                  }
                  name="numberOfBedsPerNursesDayShift"
                  onChange={handleInputChange}
                  className="prefrenceInput"
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  Day shifts
                </label>
              </div>
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  className="prefrenceInput"
                  value={
                    sectionRules?.rules?.numberOfBedsPerNursesNightShift ===
                    null
                      ? ""
                      : sectionRules?.rules?.numberOfBedsPerNursesNightShift
                  }
                  name="numberOfBedsPerNursesNightShift"
                  onChange={handleInputChange}
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  Night shifts
                </label>
              </div>
            </div>{" "}
            {/* <input
              type="number"
              placeholder="0"
              value={
                sectionRules?.rules?.numberOfBedsPerNurses === null
                  ? ""
                  : sectionRules?.rules?.numberOfBedsPerNurses
              }
              name="numberOfBedsPerNurses"
              onChange={handleInputChange}
              className={`prefrenceInput ${
                validationErrors.numberOfBedsPerNurses ? "border-red-500" : ""
              }`}
            /> */}
            {validationErrors.numberOfBedsPerNurses && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.numberOfBedsPerNurses}
              </div>
            )}
          </div>
        </div>

        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Number of Senior Nurses per shift{" "}
              <span className="text-red-500">*</span>
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Define the number of Senior Nurses required per shift
            </p>
          </div>
          <div className="col-span-2">
            <input
              type="number"
              placeholder="0"
              value={
                sectionRules?.rules?.numberOfBedsPerSeniorNurses === null
                  ? ""
                  : sectionRules?.rules?.numberOfBedsPerSeniorNurses
              }
              name="numberOfBedsPerSeniorNurses"
              onChange={handleInputChange}
              className={`prefrenceInput ${
                validationErrors.numberOfBedsPerSeniorNurses
                  ? "border-red-500"
                  : ""
              }`}
            />
            {validationErrors.numberOfBedsPerSeniorNurses && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.numberOfBedsPerSeniorNurses}
              </div>
            )}
          </div>
        </div>
        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Number of critical patients per Nurses
              {(sectionName.toLowerCase().includes("intensive care") ||
                sectionName.toLowerCase().includes("icu")) && (
                <span className="text-red-500">*</span>
              )}
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Define the number of critical patients assigned to a Nurse
            </p>
          </div>
          <div className="">
            <input
              type="number"
              placeholder="0"
              value={
                sectionRules?.rules?.numberOfCriticalPatientsPerNurses === null
                  ? ""
                  : sectionRules?.rules?.numberOfCriticalPatientsPerNurses
              }
              name="numberOfCriticalPatientsPerNurses"
              onChange={handleInputChange}
              className={`prefrenceInput ${
                validationErrors.numberOfCriticalPatientsPerNurses
                  ? "border-red-500"
                  : ""
              }`}
            />
            {validationErrors.numberOfCriticalPatientsPerNurses && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.numberOfCriticalPatientsPerNurses}
              </div>
            )}
          </div>
        </div>
        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Number of critical patients per HCA
              {(sectionName.toLowerCase().includes("intensive care") ||
                sectionName.toLowerCase().includes("icu")) && (
                <span className="text-red-500">*</span>
              )}
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Define the number of critical patients assigned to a HCA
            </p>
          </div>
          <div className="">
            <input
              type="number"
              placeholder="0"
              value={
                sectionRules?.rules?.numberOfCriticalPatientsPerHCA === null
                  ? ""
                  : sectionRules?.rules?.numberOfCriticalPatientsPerHCA
              }
              name="numberOfCriticalPatientsPerHCA"
              onChange={handleInputChange}
              className={`prefrenceInput ${
                validationErrors.numberOfCriticalPatientsPerHCA
                  ? "border-red-500"
                  : ""
              }`}
            />
            {validationErrors.numberOfCriticalPatientsPerHCA && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.numberOfCriticalPatientsPerHCA}
              </div>
            )}
          </div>
        </div>

        <hr className="border-b border-[#EBEBEB] my-[20px]" />
      </div>
    </>
  );
};

export default RulesForm;
