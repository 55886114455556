import React, { useState } from "react";
import { Modal, IconButton, Search } from "@heathmont/moon-core-tw";
import { ControlsClose } from "@heathmont/moon-icons-tw";
import RequirementCard from "../cards/need-requirement/RequirementCard";
import EmployeeCardListing from "../cards/card-employee/EmployeeCardListing";
import SimpleBar from "simplebar-react";

interface Employee {
  name: string;
  imageUrl: string;
  role: string;
  daysLeft: number;
  hoursSpent: number;
  onLeave: boolean;
  onRest: boolean;
}

const NeedModal = ({ isOpen, closeModal }: any) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [checked, setChecked] = useState<any>({});

  const handleCheckboxChange = (index: number) => {
    setChecked((prevState: any) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const employees: Employee[] = [
    {
      name: "Marvin McKinney",
      imageUrl: "images/Avatar.png",
      role: "Nurse",
      daysLeft: 16,
      hoursSpent: 48,
      onLeave: false,
      onRest: false,
    },
    {
      name: "Zachary Doe",
      imageUrl: "images/Avatar.png",
      role: "Nurse",
      daysLeft: 10,
      hoursSpent: 60,
      onLeave: true,
      onRest: false,
    },
    {
      name: "Alice Smith",
      imageUrl: "images/Avatar.png",
      role: "Leader",
      daysLeft: 8,
      hoursSpent: 40,
      onLeave: false,
      onRest: true,
    },
    {
      name: "Zachary Doe",
      imageUrl: "images/Avatar.png",
      role: "Leader",
      daysLeft: 10,
      hoursSpent: 60,
      onLeave: false,
      onRest: false,
    },
    {
      name: "Oliver Brown",
      imageUrl: "images/Avatar.png",
      role: "Nurse",
      daysLeft: 16,
      hoursSpent: 48,
      onLeave: false,
      onRest: false,
    },
    {
      name: "Marvin McKinney",
      imageUrl: "images/Avatar.png",
      role: "Nurse",
      daysLeft: 16,
      hoursSpent: 48,
      onLeave: false,
      onRest: false,
    },
    {
      name: "Zachary Doe",
      imageUrl: "images/Avatar.png",
      role: "Nurse",
      daysLeft: 10,
      hoursSpent: 60,
      onLeave: true,
      onRest: false,
    },
    {
      name: "Alice Smith",
      imageUrl: "images/Avatar.png",
      role: "Leader",
      daysLeft: 8,
      hoursSpent: 40,
      onLeave: false,
      onRest: true,
    },
    {
      name: "Zachary Doe",
      imageUrl: "images/Avatar.png",
      role: "Leader",
      daysLeft: 10,
      hoursSpent: 60,
      onLeave: false,
      onRest: false,
    },
    {
      name: "Oliver Brown",
      imageUrl: "images/Avatar.png",
      role: "Nurse",
      daysLeft: 16,
      hoursSpent: 48,
      onLeave: false,
      onRest: false,
    },
  ];

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[1153px]">
        <div className="flex flex-wrap">
          <div className="w-2/5 border-r border-beerus">
            <RequirementCard />
          </div>
          <div className="w-3/5">
            <div className="p-4 border-b border-beerus">
              <h3 className="text-moon-18 text-bulma font-semibold">
                Choose Employees
              </h3>
              <IconButton
                onClick={closeModal}
                variant="ghost"
                className="absolute top-[9px] right-[16px]"
                icon={<ControlsClose />}
              />
            </div>
            <div className="p-4">
              <Search
                onChangeSearch={setSearch}
                onChangeOpen={setOpen}
                search={"search"}
                isOpen={open}
                className="rounded-tr-[0] rounded-br-[0] h-[48px]"
              >
                <Search.Input>
                  <Search.Input.Icon />
                  <Search.Input.Input
                    className="text-moon-14 text-trunks rounded-tr-[0] rounded-br-[0] h-[48px]"
                  />
                </Search.Input>
                <Search.Transition>
                  <Search.Result>{/* Search Results */}</Search.Result>
                </Search.Transition>
              </Search>
              <div className="flex flex-wrap gap-[4px] py-[12px]">
                {employees.map((employee, index) => (
                  <span
                    key={index}
                    className={`Avatar-xs ${
                      employee.role === "Nurse"
                        ? "bg-cyan-100 text-cyan-500"
                        : employee.role === "Leader"
                        ? "bg-pink-100 text-pink-500"
                        : ""
                    } ${checked[index] ? "" : "hidden"}`}
                    onClick={() => handleCheckboxChange(index)}
                  >
                    {employee.name.charAt(0)}
                  </span>
                ))}
              </div>
              <SimpleBar className="max-h-[300px] pl-[4px]">
                <EmployeeCardListing
                  employees={employees}
                  checked={checked}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </SimpleBar>
            </div>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
};

export default NeedModal;
