import React, { useEffect, useState } from "react";
import { Avatar, Button, Modal } from "@heathmont/moon-core-tw";
import { OtherMoon } from "@heathmont/moon-icons-tw";
import { Icon } from "@iconify/react";
import DrawerEmployee from "../../Drawers/employee-detail/DrawerEmployee";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import {
  IEmployeeData,
  IShift,
} from "../../../../types/nurse-manager-interfaces/IEmployeeMonthlyScheduleResponse.interface";
import { IEmployeeDetails } from "../../../../types/nurse-manager-interfaces/IEmployeeProfileSchedule.interface";
import { getBorderColor } from "../../../Colors/colorSetting";
const employees = [
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["nurse"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["HSA"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["leader"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["Supervisor"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["nurse"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["HSA"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["leader"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["Supervisor"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["HSA"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["leader"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["Supervisor"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["nurse"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["HSA"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["leader"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["supervisor"],
  },
];

interface Props {
  data: IEmployeeData[];
  month: number;
  year: string;
  onDateSelected: (date: number, employee: string) => void;
}

const EmployeeShiftMonthly: React.FC<Props> = ({ data, onDateSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentDate = new Date();
  // const [employeeData, setEmployeeData] = useState<any>();
  const [employeeInfo] = useState<IEmployeeDetails>();
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const LeaveTypeColors: any = {
    AL: {
      bgColor: "bg-amber-100",
      textColor: "text-amber-700",
      label: "AL",
      name: "Annual Leave",
    },
    RO: {
      bgColor: "bg-cyan-100",
      textColor: "text-cyan-600",
      label: "RO",
      name: "Request Off",
    },
    CO: {
      bgColor: "bg-orange-100",
      textColor: "text-orange--600",
      label: "CO",
      name: "Compensatory Off",
    },
    CL: {
      bgColor: "bg-blue-100",
      textColor: "text-blue-600",
      label: "CL",
      name: "Compensation Leave",
    },
    EL: {
      bgColor: "bg-red-100",
      textColor: "text-red-600",
      label: "EL",
      name: "Emergency Leave",
    },
    ML: {
      bgColor: "bg-pink-100 ",
      textColor: "text-pink-600",
      label: "ML",
      name: "Maternity Leave",
    },
    PL: {
      bgColor: "bg-green-100",
      textColor: "text-green-600",
      label: "PL",
      name: "Paternity Leave",
    },
    // SL: {
    //   bgColor: "bg-yellow-100",
    //   textColor: "text-yellow-600",
    //   label: "SL",
    //   name: "Study Leave",
    // },
    UL: {
      bgColor: "bg-purple-100 ",
      textColor: "text-purple-600",
      label: "UL",
      name: "Unpaid Leave",
    },
    SL: {
      bgColor: "bg-gray-100",
      textColor: "text-gray-600",
      label: "SL",
      name: "Sick Leave",
    },
    // Add more leave types as needed
  };

  const ShiftIcons = {
    sun: "uil:sun",
    moon: "moon-icon", // Replace with your moon icon
  };
  const getShiftClassNames = (shift: any) => {
    const leaveType = shift.leaveType;
    const isDayShift = shift.isDayShift;
    const isNightShift = shift.isNightShift;
    const isOnTimeOff = shift.isOnTimeOff;

    if (leaveType) {
      const { bgColor, textColor, label } = LeaveTypeColors[leaveType] || {};
      return `${bgColor || ""} ${textColor || ""} font-semibold`;
    }

    if (isDayShift && selectedShift !== "moon" && isOnTimeOff) {
      return `text-moon-24 text-krillin`;
    }

    if (isNightShift && selectedShift !== "sun" && isOnTimeOff) {
      return `text-moon-24`;
    }

    if (isOnTimeOff && !isDayShift && !isNightShift) {
      const { bgColor, textColor, label } = LeaveTypeColors.RO || {};
      return `${bgColor || ""} ${textColor || ""} font-semibold`;
    }

    return "";
  };
  // const filters = () => {
  //   const newData = data?.map((cData: any) => {
  //     const nightShifts = cData.shifts.filter(
  //       (fil: any) => fil?.isNightShift == true,
  //     );
  //     return { ...cData, shifts: nightShifts };
  //   });
  //   return newData;
  // };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {data?.map((employee: IEmployeeData, index: any) => (
        <div className="grid grid-cols-6 w-full cursor-pointer" key={index}>
          <div className="">
            <div className="h-[43px] 2xl:px-[30px] px-3 pe-[14px] py-2 border-b border-r flex items-center justify-between">
              <div className="flex items-center">
                <Avatar
                  size="xs"
                  className={`rounded-full border-2  ${getBorderColor(
                    employee?.role
                  )} overflow-hidden`}
                  // imageUrl={employee?.avatarImageUrl}
                />
                <div className="flex-1 2xl:text-moon-14 text-moon-12  transition-colors pl-[10px] line-clamp-1 truncate 2xl:max-w-[200px] max-w-[150px]">
                  {employee?.employeeName}
                </div>
              </div>
              <div
                className={`text-[12px] bg-[#F2F5F6] rounded py-0.5 2xl:px-1.5 px-1 ${
                  (employee?.totalHoursAligned || 0) >
                  (employee?.contractHours || 0)
                    ? "text-white bg-red-600"
                    : ""
                }`}
              >
                {employee?.totalHoursAligned}
              </div>
            </div>
          </div>
          <div className="col-span-5">
            <div className={`h-[43px]  flex `} key={index}>
              {employee?.shifts?.map((shift: IShift, shiftIndex: number) => {
                return (
                  <div
                    onClick={() => onDateSelected(shiftIndex + 1, employee._id)}
                    className={`${
                      shift.day === "Saturday" || shift.day === "Sunday"
                        ? "bg-gray-200 border-sky2"
                        : currentDate.getDate() ===
                            new Date(shift.date).getDate() &&
                          currentDate.getMonth() ===
                            new Date(shift.date).getMonth()
                        ? "bg-primary border-sky2"
                        : "border-beerus"
                    } flex-1  bg-repeat relative border-b border-r border-s-0 h-full py-2 text-[14px]  justify-center inline-flex items-center ${getShiftClassNames(
                      shift
                    )}`}
                    key={shiftIndex}
                  >
                    {shift?.leaveType && (
                      <span className="font-semibold">
                        {LeaveTypeColors[shift?.leaveType]?.label}
                      </span>
                    )}
                    {shift?.isDayShift &&
                      selectedShift !== "moon" &&
                      selectedShift !== "mid-day" &&
                      selectedShift !== "mid-night" && (
                        <i className={`text-moon-24 text-krillin `}>
                          <Icon icon={ShiftIcons.sun} />
                        </i>
                      )}
                    {shift?.isNightShift &&
                      selectedShift !== "sun" &&
                      selectedShift !== "mid-day" &&
                      selectedShift !== "mid-night" && (
                        <i className="text-moon-24">
                          <OtherMoon />
                        </i>
                      )}
                    {shift?.isMidDayShift &&
                      selectedShift !== "mid-night" &&
                      selectedShift !== "sun" &&
                      selectedShift !== "moon" && (
                        <i className="text-moon-24">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M18.1 9.32L19.65 6l-4.15-.36c.6.51 1.13 1.14 1.55 1.86c.32.55.53 1.13.68 1.72C17.18 9.08 16.6 9 16 9h-.42A5.01 5.01 0 0 0 11 6c-2.76 0-5 2.24-5 5a5.01 5.01 0 0 0 3 4.58V16c0 3.87 3.13 7 7 7s7-3.13 7-7c0-3.13-2.06-5.79-4.9-6.68M8 11c0-1.65 1.35-3 3-3c1.08 0 2.03.58 2.56 1.45c-1.9.7-3.41 2.21-4.11 4.11A3 3 0 0 1 8 11m8 10c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m.5-4.75l2.86 1.69l-.75 1.22L15 17v-5h1.5zM11 4c-.84 0-1.65.15-2.39.42L11 1l2.39 3.42C12.65 4.15 11.84 4 11 4M4.95 14.5c.42.74.96 1.36 1.55 1.87L2.36 16l1.76-3.77c.14.77.41 1.55.83 2.27m-.84-4.71L2.34 6l4.16-.35A7.2 7.2 0 0 0 4.94 7.5c-.44.74-.69 1.5-.83 2.29"
                            />
                          </svg>
                        </i>
                      )}
                    {shift?.isMidNightShift &&
                      selectedShift !== "mid-day" &&
                      selectedShift !== "moon" &&
                      selectedShift !== "sun" && (
                        <i className="text-moon-24">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 56 56"
                          >
                            <path
                              fill="currentColor"
                              d="M38.524 11.125H33.18v-.117l5.46-7.195c.493-.634.68-1.032.68-1.454c0-.726-.562-1.171-1.336-1.171h-7.523c-.703 0-1.242.469-1.242 1.195c0 .773.539 1.219 1.242 1.219H35.5v.117l-5.531 7.172c-.492.656-.68.984-.68 1.476c0 .68.54 1.195 1.313 1.195h7.921c.704 0 1.22-.468 1.22-1.242c0-.703-.516-1.195-1.22-1.195m10.968 8.977H45.72v-.094l3.89-5.086c.445-.61.633-.961.633-1.383c0-.68-.539-1.125-1.265-1.125h-5.743c-.656 0-1.148.469-1.148 1.149c0 .726.492 1.171 1.148 1.171h3.399v.094l-3.867 5.063c-.446.562-.61.937-.61 1.406c0 .633.492 1.125 1.22 1.125h6.116c.68 0 1.149-.445 1.149-1.172c0-.68-.469-1.148-1.149-1.148m-23.648 34.71c8.578 0 15.515-4.312 18.68-11.648c.421-1.008.28-1.781-.165-2.25c-.422-.398-1.125-.492-1.968-.164c-1.758.703-3.914 1.102-6.61 1.102c-10.476 0-17.226-6.54-17.226-16.829c0-2.835.539-5.648 1.265-7.125c.47-.937.422-1.734.024-2.226c-.446-.516-1.242-.68-2.344-.235c-7.195 2.93-12.14 10.43-12.14 19.196c0 11.414 8.39 20.18 20.484 20.18m.047-3.562c-10.008 0-16.97-7.29-16.97-16.898c0-5.907 2.743-11.11 7.102-14.438c-.562 1.523-.89 3.867-.89 6.117c0 11.532 7.969 19.266 19.758 19.266c2.109 0 4.03-.258 5.015-.61c-2.93 4.055-8.156 6.563-14.015 6.563m13.265-23.133h-3.21v-.094l3.304-4.382c.422-.61.61-.914.61-1.313c0-.68-.516-1.078-1.196-1.078h-5.062c-.633 0-1.102.445-1.102 1.102c0 .68.469 1.101 1.102 1.101h2.812v.094l-3.258 4.36c-.422.562-.586.89-.586 1.335c0 .61.47 1.055 1.149 1.055h5.437c.633 0 1.078-.422 1.078-1.102c0-.656-.445-1.078-1.078-1.078"
                            />
                          </svg>
                        </i>
                      )}
                    {!shift?.isOnLeave &&
                      shift?.isOnTimeOff &&
                      !shift?.isDayShift &&
                      !shift?.isNightShift && (
                        <span className="font-semibold">
                          {LeaveTypeColors.RO.label}
                        </span>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )) || null}

      <DrawerEmployee
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
        employeeInfo={employeeInfo as IEmployeeDetails}
      />
    </>
  );
};

export default EmployeeShiftMonthly;

{
  /* <div
                className={`flex-1 h-[43px] border border-beerus border-s-0 justify-center inline-flex items-center`}
                key={index}
              >
                {employee.shifts.map((shift: any, shiftIndex: number) => (
                  <div
                    className={`flex-1 h-full  py-2 text-[14px] border-r border-beerus border-s-0 justify-center inline-flex items-center ${
                      shift.leaveType === "AL"
                        ? "bg-amber-100 text-amber-600"
                        : shift?.isOnTimeOff &&
                          !shift?.isNightShift &&
                          !shift?.isDayShift //shift.leaveType === "RO"
                        ? "bg-cyan-50 text-cyan-600"
                        : ""
                    }`}
                    key={shiftIndex}
                  >
                    {shift?.leaveType === "AL" ? (
                      <span className="font-semibold">{shift?.leaveType}</span>
                    ) : shift?.leaveType == "ML" ? (
                      <span className="font-semibold">{shift?.leaveType}</span>
                    ) : shift?.isDayShift ? (
                      selectedShift !== "moon" ? (
                        <i className="text-moon-24 text-krillin ">
                          <Icon icon="uil:sun" />
                        </i>
                      ) : null
                    ) : shift?.isNightShift ? (
                      selectedShift !== "sun" ? (
                        <i className="text-moon-24">
                          <OtherMoon />
                        </i>
                      ) : null
                    ) : shift?.isOnTimeOff ? (
                      <span className="font-semibold">{"RO"}</span>
                    ) : null}
                  </div>
                ))}
              </div> */
}
