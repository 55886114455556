import React, { useEffect, useState } from "react";
import TitlePreferences from "./widgets/TitlePreferences";
import { Breadcrumb, Tabs } from "@heathmont/moon-core-tw";
import { Link, useActionData, useLocation } from "react-router-dom";
import PreferencesSidebar from "./widgets/PreferencesSidebar";
import PreferencesForm from "./widgets/PreferencesForm";
import PageTitle from "../../../Components/branch-admin/ui/PageTitle";
import {
  getSectionsPref,
  getSectionsRules,
} from "../../../services/branch-admin-services/section.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ISectionPreferences } from "../../../types/branch-admin-interfaces/IWorkPreferences.interface";
import { ISection } from "../../../types/branch-admin-interfaces/ISection.interface";
import { IPreferenceForm } from "../../../types/branch-admin-interfaces/IPreferenceForm.interface";
import RulesForm from "./widgets/RulesForm";
import {
  IRulesForm,
  ISectionRules,
  Rules,
  SectionRule,
} from "../../../types/branch-admin-interfaces/IRulesForm.interface";
// interface ISection {
//   _id: string;
//   sectionName: string;
// }

// interface IHospitalInfo {
//   branchName: string;
//   hospitalName: string;
// }

// interface ISectionPreference {
//   preferences: {
//     workScheduleInHolidays: {
//       publicHolidays: boolean;
//       weekends: boolean;
//     };
//     workingHours: null | string;
//     overrideTimeOfRequests: boolean;
//     consecutiveShifts: null | string;
//     consecutiveDayShifts: null | string;
//     consecutiveNightShifts: null | string;
//     shiftDuration: null | string;
//     setToAllocateConsecutiveShifts: boolean;
//     numberOfRooms: null | number;
//     numberOfBeds: null | number;
//   };
//   _id: string;
//   branchId: string;
//   sectionId: string;
//   __v: number;
// }

// interface IPreferenceForm {
//   response?: string;
//   branchName?: string;
//   hospitalName?: string;
//   sectionPreference?: ISectionPreference;
// }

const Preferences = () => {
  const [section, setSection] = useState<ISection>();
  const [prefenceData, setPrefenceData] = useState<ISectionPreferences>();
  const [preferenceData, setPreferenceData] = useState<IPreferenceForm>();
  const [rulesFormData, setRulesFormData] = useState<IRulesForm>();
  const [sectionRules, setSectionRules] = useState<ISectionRules>();
  const [getNewData, setNewData] = useState<any>({ data: true });
  const [validationErrors, setValidationErrors] = useState({});
  const data = useSelector((state: RootState) => state.auth.User);
  const [viewOption, setViewOption] = useState<string | null>(
    "Preferences & Rules"
  );
  const [formSelect, setFormSelect] = useState<string>("preference");
  // const [hospitalInfo, setHospitalInfo] = useState<IHospitalInfo>();
  const setPrefData = (prefData: ISectionPreferences) => {
    setPrefenceData(prefData);
  };
  const revertFormData = () => {
    setRulesFormData({ ...rulesFormData });
    setPreferenceData({ ...preferenceData });
  };

  // const setHospitalData = (info: any) => {
  //   setHospitalInfo(info);
  // };

  const handlePrefForm = (formData: any) => {
    setSection(formData);
  };

  const handleGetNewData = () => {
    const data: any = true;
    setNewData({ ...data });
  };

  const breadcrumbs = [
    <Link to="">{data?.hospitalName}</Link>,
    <span>{section?.sectionName}</span>,
  ];

  const getRulesFormData = async () => {
    const response: any = await getSectionsRules(section?._id);
    if (response?.sectionRule) {
      setRulesFormData(response);
      // setSectionRules(response?.sectionRule?.rules);
    } else {
      const resetRulesForm = {
        response: "",
        branchName: "",
        hospitalName: "",
        sectionRule: {
          rules: {
            numberOfRooms: null,
            numberOfBeds: null,
            numberOfBedsPerNursesDayShift: null,
            numberOfBedsPerNursesNightShift: null,
            numberOfBedsPerHCADayShift: null,
            numberOfBedsPerHCANightShift: null,
            numberOfBedsPerSeniorNurses: null,
            numberOfCriticalPatientsPerHCA: null,
            numberOfCriticalPatientsPerNurses: null,
          },
          _id: "",
          branchId: "",
          sectionId: "",
          isActive: true,
          __v: 0,
        },
      };
      setRulesFormData({ ...resetRulesForm });
    }
  };

  const getPrefFormData = async () => {
    const response: any = await getSectionsPref(section?._id);
    if (response?.sectionPreference?.preferences) {
      setPreferenceData(response);
    } else {
      const resetPreferenceForm = {
        response: "",
        branchName: "",
        hospitalName: "",
        sectionPreference: {
          preferences: {
            workingHours: null,
            weekendsAllowedToWork: null,
            overrideTimeOfRequests: null,
            consecutiveShifts: null,
            consecutiveDayShifts: null,
            consecutiveNightShifts: null,
            shiftDuration: null,
            setToAllocateConsecutiveShifts: false,
            patientForecastUtilization: null,
            maxROThatCanBeAppliedWeekends: null,
            maxROThatCanBeApplied: null,
            shiftDistributionDayShifts: null,
            shiftDistributionNightShifts: null,
            shiftsAllocationPerMonth: null,
            overallShiftsGap: null,
            disableNurseDirectorApproval: false,
            midShiftStartTime: {
              day: "",
              dayDuration: 0,
              night: "",
              nightDuration: 0,
            },
            shiftStartTime: { day: "", night: "" },
          },
          _id: "",
          branchId: "",
          sectionId: "",
          __v: 0,
        },
      };
      setPreferenceData({ ...resetPreferenceForm });
    }
  };

  const handleFormValidationErrors = (errors: any) => {
    setValidationErrors(errors);
  };

  const handleFormShow = (event: number) => {
    if (event === 0) {
      setFormSelect("preference");
    } else if (event === 1) {
      setFormSelect("rules");
    }
  };

  useEffect(() => {
    if (section) {
      getPrefFormData();
      getRulesFormData();
    }
  }, [section, getNewData]);
  return (
    <>
      <div className="bg-slate-100 min-h-full">
        <PageTitle
          title={data?.branchName as string}
          content="See all the data with reference to the hospital"
          viewOption={viewOption}
          setViewOption={setViewOption}
        />
        <div className="flex flex-wrap">
          <div className="w-1/4">
            <div className="h-[calc(100dvh-200px)] border-r border-beerus bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)] ">
              <PreferencesSidebar
                formData={handlePrefForm}
                branchName={data?.branchName}
              />
            </div>
          </div>
          <div className="w-3/4">
            <div className="2xl:p-[20px_30px_0] p-[20px_16px_0]">
              <Breadcrumb breadcrumbs={breadcrumbs} />
              <div className="mt-6">
                <Tabs
                  onChange={(e) => {
                    handleFormShow(e);
                  }}
                >
                  <Tabs.Segment size="sm">
                    <Tabs.Pill>Preferences</Tabs.Pill>
                    <Tabs.Pill>Rules</Tabs.Pill>
                  </Tabs.Segment>
                </Tabs>
              </div>
              <TitlePreferences
                prefDataCopy={preferenceData?.sectionPreference}
                revertFormData={revertFormData}
                prefData={prefenceData}
                sectionRules={sectionRules as ISectionRules}
                sectionRulesBackup={rulesFormData?.sectionRule as SectionRule}
                sectionName={section?.sectionName}
                validationErrors={validationErrors}
                handleGetNewData={handleGetNewData}
                formSelect={formSelect}
              />
              <hr className="border-b border-[#EBEBEB] my-[20px]" />
              <div className="p-[0px_0_20px] h-[calc(100vh-424px)] overflow-y-auto">
                {formSelect === "preference" ? (
                  <PreferencesForm
                    section={section?._id as string}
                    setPrefData={setPrefData}
                    data={preferenceData}
                    validationErrors={validationErrors}
                    onValidationErrors={handleFormValidationErrors}
                  />
                ) : (
                  <RulesForm
                    sectionId={section?._id as string}
                    setSectionRules={setSectionRules}
                    sectionName={section?.sectionName || ""}
                    rulesFormData={rulesFormData as IRulesForm}
                    validationErrors={validationErrors}
                    onValidationErrors={handleFormValidationErrors}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
