import React, { useEffect, useState } from "react";
import FilterShifts from "../../../UI/FilterShifts";
import { Button } from "@heathmont/moon-core-tw";
import moment from "moment";
import { store } from "../../../../../store/store";
import {
  createAutoSchedule,
  submitSchedule,
} from "../../../../../services/nurse-manager-services/nursemanager.service";
import {
  deleteDraft,
  getDraft,
  getDraftsOfSchedules,
} from "../../../../../services/nurse-manager-services/draft.service";
import { IScheduleData } from "../../../../../types/nurse-manager-interfaces/IAutoScheduleRes.interface";
import ViewDraftsModal from "../../../Widgets/modal/ViewDraftsModal";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../../features/ToastSlice/toastSlice";
import PreferencesRulesModal from "../../../autoPreferences/PreferencesRulesModal";
import { ISchedule } from "../../../../../types/nurse-manager-interfaces/IMonthCalendar.interface";
import ReactDatePicker from "react-datepicker";
import { YearSelected } from "../../../../../types/nurse-manager-interfaces/IMonthYear.interface";
import {
  IMonthYear,
  MonthSelected,
} from "../../../../../types/nurse-manager-interfaces/IMonthYear.interface";
import RegenerateScheduleModal from "../../../autoPreferences/RegenerateScheduleModal";
import { useNavigate } from "react-router-dom";
interface Month {
  name: string;
}

export interface DraftData {
  _id: string;
  status: string;
  scheduleVersion: number;
  month: number;
  year: number;
  createdAt: string;
}

export interface Drafts {
  drafts: DraftData[];
}
// const month: Month[] = [{ name: "September 2023" }];
// const data: Data[] = [{ name: "Orthopedics" }];
// const currentYear: number = Number(moment().format("YYYY"));

// const months: any = Array.from({ length: 12 }, (_, index) => ({
//   name: `${moment().month(index).format("MMMM")}`,
//   monthName: moment().month(index).format("MMMM"),
//   label: index + 1,
// }));

// const years: any = Array.from({ length: 15 }, (_, index) => ({
//   name: currentYear - index,
//   year: currentYear - index,
//   label: "year",
// }));
interface Props {
  scheduleSubmitClick: boolean;
  setScheduleSubmitClick: (click: boolean) => void;
  setDraftData: (data: ISchedule) => void;
  isEditingDraft: boolean;
  setIsEditingDraft: (check: boolean) => void;
  loadEditDraft: boolean;
  setLoadEditDraft: (check: boolean) => void;
  submitDraftSchedule: () => void;
  setModalToExpand: (modal: string[]) => void;
  month: MonthSelected;
  year: YearSelected;
  setMonth: (month: MonthSelected) => void;
  setYear: (year: YearSelected) => void;
  fetchEmployeesForManualScheduling: (scheduleId: string) => void;
  scheduleData: ISchedule;
  // setScheduleData: (scheduleData: ISchedule) => void;
  getSchedules: (scheduleId: string) => void;
  shouldGetSchedule: boolean;
  setShouldGetSchedule: (check: boolean) => void;
}
const TopbarMonth: React.FC<Props> = ({
  scheduleSubmitClick,
  setScheduleSubmitClick,
  setDraftData,
  isEditingDraft,
  setIsEditingDraft,
  loadEditDraft,
  setLoadEditDraft,
  setModalToExpand,
  month,
  year,
  setMonth,
  setYear,
  fetchEmployeesForManualScheduling,
  scheduleData,
  // setScheduleData,
  getSchedules,
  shouldGetSchedule,
  setShouldGetSchedule,
}) => {
  const [createAutoScheduleRes, setCreateAutoScheduleRes] =
    useState<IScheduleData>();
  const [draftsOfSchedules, setDraftsOfSchedules] = useState<Drafts>();
  const [isViewDraftsOpen, setIsViewDraftsOpen] = useState<boolean>(false);
  const [draftInfo, setDraftInfo] = useState<DraftData>();
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
  const [isRegenerateOpen, setIsRegenerateOpen] = useState(false);
  const navigate = useNavigate();
  const [dateSelect, setDateSelect] = useState<Date>(
    moment().month(month?.name).year(+year?.name).toDate()
  );
  const makeAutoSchedule = async (
    scheduleId?: string,
    monthYear?: IMonthYear
  ) => {
    const monthSelected = monthYear?.monthSelected?.label ?? month?.label;
    const yearSelected = monthYear?.yearSelected?.year ?? year?.year;
    const day = monthYear?.dayStart ?? 1;
    const dayEnd =
      monthYear?.dayEnd ??
      moment()
        .month(monthSelected - 1)
        .daysInMonth();
    const res: any = await createAutoSchedule(
      day as number,
      dayEnd as number,
      monthSelected as number,
      +yearSelected,
      scheduleId
    );
    if (res?.res) {
      const scheduleDate = moment()
        .month(+monthSelected - 1)
        .year(+yearSelected)
        .toDate();
      setDateSelect(scheduleDate);
      setCreateAutoScheduleRes(res?.res as IScheduleData);
      getSchedules(res?.res?._id as string);
      navigate("/", {
        state: {
          _id: res?.res?._id,
          createdAt: res?.res?.createdAt,
          month: res?.res?.month,
          scheduleVersion: res?.res?.scheduleVersion,
          scheduledBy: res?.res?.scheduledBy,
          status: res?.res?.status,
          updatedAt: res?.res?.updatedAt,
          year: res?.res.year,
        },
      });
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(
        setToastMessage("Preferences of this section are not found")
      );
    }
    console.log("error: ", res?.err);
  };
  const fetchDraftsOfSchedules = async () => {
    const res = await getDraftsOfSchedules();
    if (res?.res) {
      // console.log("res of getDraftOfSchedule: ", res);
      setDraftsOfSchedules(res?.res as Drafts);
    }
  };
  const fetchDraftToEdit = async () => {
    const res: any = await getDraft(draftInfo?._id as string);
    if (res?.res) {
      const date = moment()
        .month((res?.res?.month as number) - 1)
        .year(+res?.res?.year)
        .toDate();
      handleDateChange(date);
      fetchEmployeesForManualScheduling(draftInfo?._id as string);
      const temp = { schedule: res?.res };
      setIsEditingDraft(true);
      setDraftData(temp as ISchedule);
    }
  };

  useEffect(() => {
    setDateSelect(moment().month(month?.name).year(+year?.name).toDate());
  }, [month, year]);

  const removeDraft = async (draftId: string) => {
    const res = await deleteDraft(draftId);
    if (res?.res) {
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Draft deleted successfully"));
      fetchDraftsOfSchedules();
      // setDraftsOfSchedules(res?.res as Drafts);
      // const temp = { schedule: res?.res };
      // setDraftData(temp as IDraft);
    }
  };

  const submitForSchedule = async (draftId?: string) => {
    const res = await submitSchedule(scheduleData?.schedule?._id, draftId);
    if (res?.res) {
      setScheduleSubmitClick(!scheduleSubmitClick); //may be not needed here
      // setSnackbar("success");
      // setToastMessage("Schedule submitted successfully");
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Schedule submitted successfully"));
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(
        setToastMessage("Schedule already exists for the selected month")
      );
    }
  };
  const openPreferencesModal = () => setIsPreferencesOpen(true);

  const handleDateChange = (date: Date, scheduleChange?: boolean) => {
    const month = {
      name: moment(date).format("MMMM"),
      monthName: moment(date).format("MMMM"),
      label: moment(date).month() + 1,
    };
    const year = {
      name: moment(date).format("YYYY"),
      year: moment(date).format("YYYY"),
      label: "year",
    };
    setMonth({ ...month });
    setYear({ ...year });
    setDateSelect(date);
    setCreateAutoScheduleRes(undefined);
    if (scheduleChange) setShouldGetSchedule(!shouldGetSchedule);
  };
  useEffect(() => {
    if (!draftInfo) return;
    setIsEditingDraft(true);
    fetchDraftToEdit();
  }, [loadEditDraft]);

  return (
    <div className="bg-white shadow-md py-4 px-5">
      <div className="flex items-center flex-wrap -mx-2">
        <div className="w-3/5 px-2">
          <div className="flex flex-wrap gap-3 monthCalendar">
            {!isEditingDraft && (
              <ReactDatePicker
                className="border-2 rounded-md mx-1 justify-end items-center"
                dateFormat="MMMM yyyy"
                showIcon
                showMonthYearPicker
                selected={dateSelect}
                onChange={(date) => handleDateChange(date as Date, true)}
              />
            )}
            <div>
              <FilterShifts />
            </div>
            <div className="flex justify-end items-center gap-2">
              {isEditingDraft && (
                <Button
                  className="min-h-[42px]"
                  variant="ghost"
                  onClick={() => {
                    handleDateChange(moment()?.toDate());
                    setScheduleSubmitClick(!scheduleSubmitClick);
                  }}
                >
                  Back to Schedule
                </Button>
              )}

              <div className="flex gap-2">
                <Button
                  className="min-h-[42px] bg-[rgb(36,156,186,0.15)] text-hit"
                  onClick={() => {
                    setIsRegenerateOpen(true);
                    // setIsOpenConfirmation(true);
                    // makeAutoSchedule(scheduleData?.schedule?._id);
                  }}
                >
                  Regenerate Schedule
                </Button>
                {(isEditingDraft ||
                  scheduleData?.schedule?.status === "draft") && (
                  <Button
                    className="min-h-[42px] bg-[rgb(36,156,186,0.15)] text-hit"
                    onClick={() => {
                      submitForSchedule(
                        isEditingDraft ? (draftInfo?._id as string) : undefined
                      );
                    }}
                  >
                    Submit Draft Schedule
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <ViewDraftsModal
          isOpen={isViewDraftsOpen}
          setIsOpen={setIsViewDraftsOpen}
          draftsOfSchedules={draftsOfSchedules as Drafts}
          removeDraft={removeDraft}
          draftId={draftInfo?._id as string}
          setDraftInfo={setDraftInfo}
          loadEditDraft={loadEditDraft}
          setLoadEditDraft={setLoadEditDraft}
          submitForSchedule={submitForSchedule}
          setModalToExpand={setModalToExpand}
        />
        <RegenerateScheduleModal
          isOpen={isRegenerateOpen}
          setIsOpen={setIsRegenerateOpen}
          makeAutoSchedule={makeAutoSchedule}
          scheduleId={
            isEditingDraft
              ? (draftInfo?._id as string)
              : scheduleData?.schedule?._id
          }
          month={
            isEditingDraft
              ? {
                  label: draftInfo?.month as number,
                  monthName: moment()
                    .month((draftInfo?.month as number) - 1)
                    .format("MMMM"),
                  name: moment()
                    .month((draftInfo?.month as number) - 1)
                    .format("MMMM"),
                }
              : month
          }
          // month={month}
          setMonth={setMonth}
          year={
            isEditingDraft
              ? {
                  label: "year",
                  year: (draftInfo?.year as number)?.toString(),
                  name: (draftInfo?.year as number)?.toString(),
                }
              : year
          }
          // year={year}
          setYear={setYear}
        />
        {!isEditingDraft && (
          <div className="flex w-2/5 justify-end">
            <div className="flex flex-arap items-center gap-3 ">
              {(createAutoScheduleRes || scheduleData?.schedule._id) && (
                <div className="text-moon-14 gap-2">
                  <span className=" min-h-[42px]  text-[#8697A2]">
                    Status:{" "}
                  </span>
                  <span>{scheduleData?.schedule?.status?.toUpperCase()}</span>
                </div>
              )}
              <Button
                variant="ghost"
                className="min-h-[42px]"
                onClick={() => {
                  setIsViewDraftsOpen(true);
                  fetchDraftsOfSchedules();
                }}
              >
                View Drafts
              </Button>
              <Button
                variant="tertiary"
                className="text-hit bg-[rgb(36,156,186,0.15)] min-h-[42px]"
                onClick={openPreferencesModal}
              >
                Generate Schedule
              </Button>
              <PreferencesRulesModal
                isOpen={isPreferencesOpen}
                setIsOpen={setIsPreferencesOpen}
                makeAutoSchedule={makeAutoSchedule}
                month={month}
                setMonth={setMonth}
                year={year}
                setYear={setYear}
              />
              {/* <Button
                  variant="tertiary"
                  className="text-hit bg-[rgb(36,156,186,0.15)] min-h-[42px]"
                  iconLeft={<SoftwarePlate />}
                  onClick={() => {
                    setSelectedDate(scheduleInitialDate);
                    store?.dispatch(setSelectedShift("sun"));
                    // openModal();
                    makeAutoSchedule();
                    setCreateAutoScheduleRes(undefined);
                  }}
                >
                  Auto Allocate
                </Button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopbarMonth;
