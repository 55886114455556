import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFilter } from "../../../../features/nurse-manager/Week Filter/weekSlice";
const items: string[] = ["All", "Senior Nurse", "Nurse", "HCA"];
const showDot = true;
interface Props {
  setSelectedFilter: (designation: string) => void;
}
const TrackingTabsAccordion: React.FC<Props> = ({ setSelectedFilter }) => {
  const [selectedButton, setSelectedButton] = useState(
    items && items.length > 0 ? items[0] : null
  );

  const handleButtonClick = (button: any) => {
    setSelectedButton(button);
    if (button == "Nurse") {
      setSelectedFilter("nurse");
      // fetchScheduleByDayForNurse();
    } else if (button == "HCA") {
      setSelectedFilter("HCA");
      // fetchScheduleByDayForHCA();
    } else if (button == "Senior Nurse") {
      setSelectedFilter("senior nurse");
    } else {
      setSelectedFilter("All");
      // allEmployeesFilter();
    }
  };

  // Check if items exist and is an array
  if (!items || !Array.isArray(items)) {
    return null; // Return null or a fallback component if items is invalid
  }
  const getButtonDotColor = (item: string) => {
    if (item === "Senior Nurse") {
      return "dodoria";
    } else if (item === "Nurse") {
      return "purple-600";
    } else if (item === "HCA") {
      return "green-600";
    }
    return "black";
  };
  return (
    <div className="flex items-center justify-center w-full gap-[2px]  bg-goku rounded-moon-s-sm ">
      {items.map((item, index) => (
        <button
          key={index}
          className={`px-1 py-1 gap-1 flex items-center justify-center w-full font-regular whitespace-nowrap 2xl:text-moon-12 text-moon-12  rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
            selectedButton === item
              ? "bg-gohan text-slate-900 font-semibold"
              : "text-trunks font-regular"
          }`}
          onClick={() => {
            handleButtonClick(item);
          }}
        >
          {item !== "All" && showDot && (
            <span className={`dot text-${getButtonDotColor(item)}`}>
              <Icon icon="material-symbols:circle" />
            </span>
          )}

          {item}
        </button>
      ))}
    </div>
  );
};

export default TrackingTabsAccordion;
