import React from "react";
import {
  ColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { IconButton } from "@heathmont/moon-core-tw";
import {
  ControlsChevronRightSmall,
  ControlsChevronLeftSmall,
} from "@heathmont/moon-icons-tw";
import SimpleBar from "simplebar-react";

// Generic type for the data
type Data = Record<string, any>;

// Column definition type
export type ColumnDef<T extends Data> = {
  header: string;
  accessorKey: keyof T;
  cell?: (info: any) => React.ReactNode;
  enableSorting?: boolean;
};

type GenericTableProps<T extends Data> = {
  data: T[];
  columns: any;
  currentPage?: number;
  totalPages?: number;
  getData?: (page: number) => void;
  limit?: number;
  totalItems?: number;
  noPagination?: boolean;
};

const GenericTable = <T extends Data>({
  data,
  columns,
  currentPage = 1,
  totalPages = 1,
  limit = 10,
  totalItems = 1,
  getData,
  noPagination = false,
}: GenericTableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleNext = React.useCallback(() => {
    if (currentPage < totalPages) {
      getData?.(currentPage + 1);
    }
  }, [currentPage, totalPages]);

  const handlePrevious = React.useCallback(() => {
    if (currentPage > 1) {
      getData?.(currentPage - 1);
    }
  }, [currentPage]);

  const handlePageClick = React.useCallback((page: number) => {
    getData?.(page);
  }, []);

  return (
    <>
      <SimpleBar className="overflow-auto   max-h-[calc(100vh-300px)] px-6">
        <table className="min-w-full border-b rounded-lg overflow-hidden  bg-white">
          <thead className="bg-neutral-200">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-4 py-2 text-left  capitalize font-semibold text-xs h-12 sticky top-0 z-10 bg-neutral-200"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="px-4 py-2 border-t border-neutral-100 text-sm text-black"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </SimpleBar>
      {!noPagination && (
        <div className="flex items-center justify-end p-4 px-6 gap-4">
          <div className="text-sm">
            Showing {limit * (currentPage - 1) + 1} -{" "}
            {Math.min(limit * currentPage, totalItems)} of {totalItems}
          </div>
          <div className="flex items-center space-x-2">
            <IconButton
              icon={<ControlsChevronLeftSmall />}
              variant="secondary"
              size="sm"
              onClick={handlePrevious}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                className={`w-8 h-8 rounded ${
                  currentPage === index + 1
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-800"
                }`}
                onClick={() => handlePageClick(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <IconButton
              icon={<ControlsChevronRightSmall />}
              variant="secondary"
              size="sm"
              onClick={handleNext}
              disabled={currentPage === totalPages}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GenericTable;
