import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFilter } from "../../../../features/nurse-manager/Week Filter/weekSlice";
interface props {
  items: string[];
  showDot?: boolean;
}
const TrackingTabs = ({ items, showDot = true }: props) => {
  const dispatch = useDispatch();
  const [selectedButton, setSelectedButton] = useState(
    items && items.length > 0 ? items[0] : null
  );

  const handleButtonClick = (button: any) => {
    setSelectedButton(button);
    if (button == "Nurse") {
      dispatch(setSelectedFilter("Nurse"));
      // fetchScheduleByDayForNurse();
    } else if (button == "HCA") {
      dispatch(setSelectedFilter("HCA"));
      // fetchScheduleByDayForHCA();
    } else if (button == "Senior Nurse") {
      dispatch(setSelectedFilter("Senior Nurse"));
    } else {
      dispatch(setSelectedFilter("All"));
      // allEmployeesFilter();
    }
  };

  // Check if items exist and is an array
  if (!items || !Array.isArray(items)) {
    return null; // Return null or a fallback component if items is invalid
  }
  const getButtonDotColor = (item: string) => {
    if (item === "Senior Nurse") {
      return "dodoria";
    } else if (item === "Nurse") {
      return "whis";
    } else if (item === "HCA") {
      return "krillin";
    }
    return "black";
  };

  return (
    <div className="flex items-center justify-center w-fit gap-2 p-1 bg-goku rounded-moon-s-sm">
      {items.map((item, index) => (
        <button
          key={index}
          className={`2xl:px-2 px-2 py-1 gap-1 flex items-center justify-center w-full font-regular whitespace-nowrap text-moon-12  rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
            selectedButton === item
              ? "bg-gohan text-slate-900 font-semibold"
              : "text-trunks font-regular"
          }`}
          onClick={() => {
            handleButtonClick(item);
          }}
        >
          {item !== "All" && showDot && (
            <span className={`dot text-${getButtonDotColor(item)}`}>
              <Icon icon="material-symbols:circle" />
            </span>
          )}

          {item}
        </button>
      ))}
    </div>
  );
};

export default TrackingTabs;
