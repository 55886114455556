import React from "react";
import { Rules } from "../../../types/branch-admin-interfaces/IRulesForm.interface";
import SimpleBar from "simplebar-react";
import { Input } from "@heathmont/moon-core-tw";

interface Props {
  rules: Rules;
  errors: { [key: string]: string };
  onChange: (field: keyof Rules, value: number | null) => void;
}

const AllocateRules: React.FC<Props> = ({ rules, errors, onChange }) => {
  // Helper to convert string input to number or null if empty.
  const handleInputChange = (field: keyof Rules, value: string): void => {
    onChange(field, value === "" ? null : Number(value));
  };

  return (
    <div className="bg-[#f5f5f5] py-5 rounded-lg">
      <h3 className="text-lg text-[#272D36] mb-6 font-semibold px-5">Rules</h3>
      <SimpleBar className="h-[284px] overflow-y-auto px-5">
        {/* Beds and Rooms */}
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="grid gap-2">
            <label className="text-sm text-[#272D36] font-semibold">
              Number of beds in section
            </label>
            <Input
              type="number"
              value={rules?.numberOfBeds ?? ""}
              onChange={(e) =>
                handleInputChange("numberOfBeds", e.target.value)
              }
            />
            {errors.numberOfBeds && (
              <span className="text-red-500 text-sm">
                {errors.numberOfBeds}
              </span>
            )}
          </div>
          <div className="grid gap-2">
            <label className="text-sm text-[#272D36] font-semibold">
              Number of rooms in section
            </label>
            <Input
              type="number"
              value={rules?.numberOfRooms ?? ""}
              onChange={(e) =>
                handleInputChange("numberOfRooms", e.target.value)
              }
            />
            {errors.numberOfRooms && (
              <span className="text-red-500 text-sm">
                {errors.numberOfRooms}
              </span>
            )}
          </div>
        </div>

        {/* Number of patients per HCA */}
        <div className="flex justify-between gap-3 items-center mb-4">
          <p className="text-base text-[#272D36] font-semibold flex-1">
            Number of patients per HCA
          </p>
          <div className="flex-1 flex items-center gap-3">
            <label className="text-sm text-[#8697A2]">Day shift</label>
            <div className="flex-1">
              <Input
                type="number"
                value={rules?.numberOfBedsPerHCADayShift ?? ""}
                onChange={(e) =>
                  handleInputChange(
                    "numberOfBedsPerHCADayShift",
                    e.target.value
                  )
                }
              />
              {errors.numberOfBedsPerHCADayShift && (
                <span className="text-red-500 text-sm">
                  {errors.numberOfBedsPerHCADayShift}
                </span>
              )}
            </div>
            <label className="text-sm text-[#8697A2]">Night shift</label>
            <div className="flex-1">
              <Input
                type="number"
                value={rules?.numberOfBedsPerHCANightShift ?? ""}
                onChange={(e) =>
                  handleInputChange(
                    "numberOfBedsPerHCANightShift",
                    e.target.value
                  )
                }
              />
              {errors.numberOfBedsPerHCANightShift && (
                <span className="text-red-500 text-sm">
                  {errors.numberOfBedsPerHCANightShift}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Number of patients per Nurse */}
        <div className="flex justify-between gap-3 items-center mb-4">
          <p className="text-base text-[#272D36] font-semibold flex-1">
            Number of patients per Nurse
          </p>
          <div className="flex-1 flex items-center gap-3">
            <label className="text-sm text-[#8697A2]">Day shift</label>
            <div className="flex-1">
              <Input
                type="number"
                value={rules?.numberOfBedsPerNursesDayShift ?? ""}
                onChange={(e) =>
                  handleInputChange(
                    "numberOfBedsPerNursesDayShift",
                    e.target.value
                  )
                }
              />
              {errors.numberOfBedsPerNursesDayShift && (
                <span className="text-red-500 text-sm">
                  {errors.numberOfBedsPerNursesDayShift}
                </span>
              )}
            </div>
            <label className="text-sm text-[#8697A2]">Night shift</label>
            <div className="flex-1">
              <Input
                type="number"
                value={rules?.numberOfBedsPerNursesNightShift ?? ""}
                onChange={(e) =>
                  handleInputChange(
                    "numberOfBedsPerNursesNightShift",
                    e.target.value
                  )
                }
              />
              {errors.numberOfBedsPerNursesNightShift && (
                <span className="text-red-500 text-sm">
                  {errors.numberOfBedsPerNursesNightShift}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Number of Senior Nurses per shift */}
        <div className="flex justify-between gap-3 items-center mb-4">
          <p className="text-base text-[#272D36] font-semibold flex-1">
            Number of Senior Nurses per shift
          </p>
          <div className="flex items-center gap-3">
            <div className="flex-1">
              <Input
                type="number"
                value={rules?.numberOfBedsPerSeniorNurses ?? ""}
                onChange={(e) =>
                  handleInputChange(
                    "numberOfBedsPerSeniorNurses",
                    e.target.value
                  )
                }
              />
              {errors.numberOfBedsPerSeniorNurses && (
                <span className="text-red-500 text-sm">
                  {errors.numberOfBedsPerSeniorNurses}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Critical Patients Fields */}
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="grid gap-2">
            <label className="text-sm text-[#272D36] font-semibold">
              Number of Critical Patients per Nurse
            </label>
            <Input
              type="number"
              value={rules?.numberOfCriticalPatientsPerNurses ?? ""}
              onChange={(e) =>
                handleInputChange(
                  "numberOfCriticalPatientsPerNurses",
                  e.target.value
                )
              }
            />
            {errors.numberOfCriticalPatientsPerNurses && (
              <span className="text-red-500 text-sm">
                {errors.numberOfCriticalPatientsPerNurses}
              </span>
            )}
          </div>
          <div className="grid gap-2">
            <label className="text-sm text-[#272D36] font-semibold">
              Number of Critical Patients per HCA
            </label>
            <Input
              type="number"
              value={rules?.numberOfCriticalPatientsPerHCA ?? ""}
              onChange={(e) =>
                handleInputChange(
                  "numberOfCriticalPatientsPerHCA",
                  e.target.value
                )
              }
            />
            {errors.numberOfCriticalPatientsPerHCA && (
              <span className="text-red-500 text-sm">
                {errors.numberOfCriticalPatientsPerHCA}
              </span>
            )}
          </div>
        </div>
      </SimpleBar>
    </div>
  );
};

export default AllocateRules;
