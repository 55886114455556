import { api } from "../../utils/axios";
export const postEmployee = async (employee: any) => {
  try {
    const { data, ok } = await api.post(`/employee/createEmployee`, employee);
    if (ok) {
      return data;
    }
    return { err: data };
  } catch (error) {
    return { err: error };
  }
};
export const updateEmployee = async (employee: any) => {
  const { _id, employeeId, password, hospital, branch, resetToken, ...body } =
    employee;
  try {
    const { data, ok } = await api.patch(
      `/employee/updateEmployeeInfo/${_id}`,
      body
    );
    if (ok) {
      return data;
    }
    return { err: data };
  } catch (error) {
    return { err: error };
  }
};

export const deleteEmployee = async (id: string) => {
  try {
    const { data, ok } = await api.delete(`/employee/deleteEmployee/${id}`);
    if (ok) {
      return data;
    }
    throw new Error("Invalid Response");
  } catch (error) {
    return { err: error };
  }
};
