import React, { useEffect, useState } from "react";
import { Modal, Button, Tabs } from "@heathmont/moon-core-tw";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";

import AllocateRules from "./AllocateRules";
import AllocatePreferences from "./AllocatePreferences";
import { getPreferencesAndRules } from "../../../services/nurse-manager-services/nursemanager.service";
import { IPreferencesAndRules } from "../../../types/nurse-manager-interfaces/IPreferencesAndRules.interface";
import { RootState, store } from "../../../store/store";
import {
  setSnackBar,
  setToastMessage,
} from "../../../features/ToastSlice/toastSlice";
import { Rules } from "../../../types/branch-admin-interfaces/IRulesForm.interface";
import { Preferences } from "../../../types/branch-admin-interfaces/ISectionPreference.interface";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import {
  IMonthYear,
  MonthSelected,
  YearSelected,
} from "../../../types/nurse-manager-interfaces/IMonthYear.interface";
import {
  setSectionsPref,
  setSectionsRules,
} from "../../../services/branch-admin-services/section.service";
import { useSelector } from "react-redux";

interface Props {
  isOpen: boolean;
  setIsOpen: (check: boolean) => void;
  makeAutoSchedule: (id?: string, monthYear?: IMonthYear) => void;
  scheduleId: string;
  month: MonthSelected;
  setMonth: (month: MonthSelected) => void;
  year: YearSelected;
  setYear: (year: YearSelected) => void;
}

const RegenerateScheduleModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  makeAutoSchedule,
  scheduleId,
  month,
  setMonth,
  year,
  setYear,
}) => {
  const user = useSelector((state: RootState) => state.auth.User);
  const [formSelect, setFormSelect] = useState<string>("preferences");
  const [calendarSelect, setCalendarSelect] = useState<string>("byMonth");
  const [isRuleUpdated, setIsRulesUpdated] = useState(false);
  const [isPrefUpdated, setIsPrefUpdated] = useState(false);
  const [dateSelect, setDateSelect] = useState<Date>();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [preferences, setPreferences] = useState<Preferences>({
    workingHours: 0,
    weekendsAllowedToWork: 0,
    maxROThatCanBeApplied: 0,
    maxROThatCanBeAppliedWeekends: 0,
    overrideTimeOfRequests: 0,
    consecutiveDayShifts: 0,
    consecutiveNightShifts: 0,
    consecutiveShifts: 0,
    shiftDuration: 0,
    setToAllocateConsecutiveShifts: false,
    patientForecastUtilization: 0,
    shiftDistributionDayShifts: 0,
    shiftDistributionNightShifts: 0,
    shiftsAllocationPerMonth: 0,
    disableNurseDirectorApproval: false,
    midShiftStartTime: { day: "", dayDuration: 0, night: "", nightDuration: 0 },
    shiftStartTime: { day: "", night: "" },
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [rulesErrors, setRulesErrors] = useState<
    Partial<Record<keyof Rules, string>>
  >({});
  const [rules, setRules] = useState<Rules>({
    numberOfRooms: null,
    numberOfBeds: null,
    numberOfBedsPerNursesDayShift: null,
    numberOfBedsPerNursesNightShift: null,
    numberOfBedsPerHCADayShift: null,
    numberOfBedsPerHCANightShift: null,
    numberOfBedsPerSeniorNurses: null,
    numberOfCriticalPatientsPerNurses: null,
    numberOfCriticalPatientsPerHCA: null,
  });

  // onChange callback to update the rules state.
  const handleRulesChange = (field: keyof Rules, value: number | null) => {
    setIsRulesUpdated(true);
    setRules((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Validate the rules. Adjust validations as required.
  const validateRules = () => {
    const newErrors: Partial<Record<keyof Rules, string>> = {};

    if (rules.numberOfBeds == null || rules.numberOfBeds <= 0) {
      newErrors.numberOfBeds =
        "Number of beds is required and must be greater than 0.";
    }
    if (rules.numberOfRooms == null || rules.numberOfRooms <= 0) {
      newErrors.numberOfRooms =
        "Number of rooms is required and must be greater than 0.";
    }
    if (
      rules.numberOfBedsPerHCADayShift == null ||
      rules.numberOfBedsPerHCADayShift <= 0
    ) {
      newErrors.numberOfBedsPerHCADayShift =
        "Beds per HCA (Day shift) is required and must be greater than 0.";
    }
    if (
      rules.numberOfBedsPerHCANightShift == null ||
      rules.numberOfBedsPerHCANightShift <= 0
    ) {
      newErrors.numberOfBedsPerHCANightShift =
        "Beds per HCA (Night shift) is required and must be greater than 0.";
    }
    if (
      rules.numberOfBedsPerNursesDayShift == null ||
      rules.numberOfBedsPerNursesDayShift <= 0
    ) {
      newErrors.numberOfBedsPerNursesDayShift =
        "Beds per Nurse (Day shift) is required and must be greater than 0.";
    }
    if (
      rules.numberOfBedsPerNursesNightShift == null ||
      rules.numberOfBedsPerNursesNightShift <= 0
    ) {
      newErrors.numberOfBedsPerNursesNightShift =
        "Beds per Nurse (Night shift) is required and must be greater than 0.";
    }
    if (
      rules.numberOfCriticalPatientsPerNurses == null ||
      rules.numberOfCriticalPatientsPerNurses < 0
    ) {
      newErrors.numberOfCriticalPatientsPerNurses =
        "Critical patients per Nurse must be 0 or more.";
    }
    if (
      rules.numberOfCriticalPatientsPerHCA == null ||
      rules.numberOfCriticalPatientsPerHCA < 0
    ) {
      newErrors.numberOfCriticalPatientsPerHCA =
        "Critical patients per HCA must be 0 or more.";
    }

    setRulesErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateSchedule = async () => {
    if (!validateForm()) {
      setFormSelect("preference");
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please fix all validations"));
      return;
    }
    if (!validateRules()) {
      setFormSelect("rules");
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please fix all validations"));
      return;
    }
    if (isPrefUpdated) {
      await setSectionsPref({ sectionId: user?.assignedSection, preferences });
    }
    if (isRuleUpdated) {
      await setSectionsRules({ rules, sectionId: user?.assignedSection || "" });
    }
    const monthYear = handleDateSet();
    makeAutoSchedule(scheduleId, monthYear);
    closeModal();
  };

  // Callback passed to child to update a specific field
  const handleChange = (field: string, value: any) => {
    let tempData: any = { ...preferences };
    if (field.includes(".")) {
      const shift = field.split(".");
      tempData[shift[0]] = {
        ...tempData[shift[0]],
        [shift[1]]: value,
      };
      setPreferences(tempData);
      setIsPrefUpdated(true);
    } else {
      setIsPrefUpdated(true);
      setPreferences((prev) => ({ ...prev, [field]: value }));
    }
  };

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const handleFormSelect = (event: number) => {
    if (event === 0) {
      setFormSelect("preferences");
    } else if (event === 1) {
      setFormSelect("rules");
    }
  };

  const handleCalendarSelect = (event: number) => {
    if (event === 0) {
      setCalendarSelect("byMonth");
    } else if (event === 1) {
      // setStartDate(new Date());
      // setEndDate(new Date());
      setStartDate(
        moment()
          .month(month?.label - 1)
          .year(+year?.name)
          .toDate()
      );
      setEndDate(
        moment()
          .month(month?.label - 1)
          .year(+year?.name)
          .toDate()
      );
      setCalendarSelect("byDate");
    }
  };

  const fetchPreferencesAndRules = async () => {
    const res = (await getPreferencesAndRules()) as IPreferencesAndRules;
    if (res?.preferences || res?.rules) {
      setPreferences(res.preferences);
      setRules(res.rules);
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(
        setToastMessage("Couldn't fetch current Preferences and Rules")
      );
    }
  };

  // const date = moment().month(month?.name).year(+year?.name).toDate();
  const handleDateChange = (dates: [Date, Date] | Date) => {
    if (Array.isArray(dates)) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(dates);
    }
  };

  const handleDateSet = () => {
    const month = {
      name: moment(startDate).format("MMMM"),
      monthName: moment(startDate).format("MMMM"),
      label: moment(startDate).month() + 1,
    };
    const year = {
      name: moment(startDate).format("YYYY"),
      year: moment(startDate).format("YYYY"),
      label: "year",
    };

    const dayStart =
      calendarSelect === "byMonth"
        ? moment(startDate).startOf("month").format("DD")
        : moment(startDate).format("DD");

    const dayEnd =
      calendarSelect === "byMonth"
        ? moment(startDate).endOf("month").format("DD")
        : moment(endDate).format("DD");
    setMonth({ ...month });
    setYear({ ...year });
    return {
      monthSelected: month,
      yearSelected: year,
      dayStart: +dayStart,
      dayEnd: +dayEnd,
    };
  };
  useEffect(() => {
    if (!isOpen) return;
    fetchPreferencesAndRules();
    setFormSelect("preferences");
    setCalendarSelect("byMonth");
    setStartDate(
      moment()
        // .day(new Date().getDate())
        .month(month?.label - 1)
        .year(+year?.name)
        .toDate()
    );
    setDateSelect(
      moment()
        .month(month.label - 1)
        .year(+year.year)
        .toDate()
    );
  }, [isOpen]);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    // Example: Treat null as 0 for shift distributions.
    if (
      (preferences.shiftDistributionDayShifts ?? 0) +
        (preferences.shiftDistributionNightShifts ?? 0) >
      100
    ) {
      newErrors.shiftDistribution = "Shift Distribution should be 0% - 100%.";
    }

    if (
      preferences.workingHours != null &&
      (preferences.workingHours < 1 || preferences.workingHours > 12)
    ) {
      newErrors.workingHours = "Working hours should be 1 - 12 hours.";
    }

    if (
      preferences.weekendsAllowedToWork != null &&
      (preferences.weekendsAllowedToWork < 0 ||
        preferences.weekendsAllowedToWork > 10)
    ) {
      newErrors.weekendsAllowedToWork =
        "Weekends allowed to work should be 0 - 10.";
    }

    if (
      preferences.consecutiveShifts != null &&
      (preferences.consecutiveShifts < 2 || preferences.consecutiveShifts > 30)
    ) {
      newErrors.consecutiveShifts = "Consecutive shifts should be 2 - 30.";
    }

    if (
      preferences.consecutiveDayShifts != null &&
      (preferences.consecutiveDayShifts < 2 ||
        preferences.consecutiveDayShifts > 30)
    ) {
      newErrors.consecutiveDayShifts =
        "Consecutive day shifts should be between 2 and 30.";
    }

    if (
      preferences.consecutiveNightShifts != null &&
      (preferences.consecutiveNightShifts < 2 ||
        preferences.consecutiveNightShifts > 30)
    ) {
      newErrors.consecutiveNightShifts =
        "Consecutive night shifts should be between 2 and 30.";
    }

    if (
      preferences.overrideTimeOfRequests != null &&
      (preferences.overrideTimeOfRequests < 0 ||
        preferences.overrideTimeOfRequests > 100)
    ) {
      newErrors.overrideTimeOfRequests =
        "Override Time Of Requests should be 0% - 100%.";
    }

    if (
      preferences.patientForecastUtilization != null &&
      (preferences.patientForecastUtilization < 0 ||
        preferences.patientForecastUtilization > 100)
    ) {
      newErrors.patientForecastUtilization =
        "Forecast Patient Utilization should be 0% - 100%.";
    }

    // Even if the individual day shift distribution is null,
    // we can use a default value (here, 0) to validate.
    if (
      (preferences.shiftDistributionDayShifts ?? 0) < 0 ||
      (preferences.shiftDistributionDayShifts ?? 0) > 100
    ) {
      newErrors.shiftDistributionDayShifts =
        "Shift Distribution should be 0% - 100%.";
    }

    if (
      preferences.shiftDuration != null &&
      (preferences.shiftDuration < 1 || preferences.shiftDuration > 12)
    ) {
      newErrors.shiftDuration = "Shift Duration should be between 1 to 12.";
    }

    if (
      preferences.maxROThatCanBeApplied != null &&
      (preferences.maxROThatCanBeApplied < 0 ||
        preferences.maxROThatCanBeApplied > 5)
    ) {
      newErrors.maxROThatCanBeApplied = "Max RO to apply should be 0 - 5.";
    }

    if (
      preferences.maxROThatCanBeAppliedWeekends != null &&
      (preferences.maxROThatCanBeAppliedWeekends < 0 ||
        preferences.maxROThatCanBeAppliedWeekends > 5)
    ) {
      newErrors.maxROThatCanBeAppliedWeekends =
        "Max RO to apply on weekends should be 0 - 1.";
    }

    if (
      preferences.shiftsAllocationPerMonth != null &&
      (preferences.shiftsAllocationPerMonth < 0 ||
        preferences.shiftsAllocationPerMonth > 31)
    ) {
      newErrors.shiftsAllocationPerMonth =
        "Shift Allocation Per Month should be between 0 - 31.";
    }

    if (
      preferences?.midShiftStartTime?.dayDuration != null &&
      (preferences?.midShiftStartTime?.dayDuration < 8 ||
        preferences?.midShiftStartTime?.dayDuration > 12)
    ) {
      newErrors.midDayShift = "Mid Shift hours should be between 8 - 12";
    }
    if (
      preferences?.midShiftStartTime?.nightDuration != null &&
      (preferences?.midShiftStartTime?.nightDuration < 8 ||
        preferences?.midShiftStartTime?.nightDuration > 12)
    ) {
      newErrors.midNightShift = "Mid Shift hours should be between 8 - 12";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      <Modal open={isOpen} onClose={closeModal}>
        <Modal.Backdrop />
        <Modal.Panel className="AutoPrefrences max-w-[900px]">
          <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
            <h3 className="text-moon-18 text-bulma font-semibold">
              Regenerate Schedule
            </h3>
            <Button className="bg-transparent" onClick={closeModal}>
              <ControlsCloseSmall className="w-6 h-6 text-[#000000]" />
            </Button>
          </div>
          <div className="p-4">
            <div className="flex justify-between items-center mb-6">
              <Tabs
                onChange={(e) => {
                  handleFormSelect(e);
                }}
              >
                <Tabs.Segment size="sm">
                  <Tabs.Pill>Preferences</Tabs.Pill>
                  <Tabs.Pill>Rules</Tabs.Pill>
                </Tabs.Segment>
              </Tabs>
              {/* <p className="flex items-center text-[12px] text-[#8697A2]">
                <GenericInfo className="me-3 w-6 h-6" />
                Select month and year to create schedule
              </p> */}
              <div className="flex max-w-[401px] gap-3 w-full ">
                {/* <Input type="date" aria-label="Date" placeholder="Calendar" /> */}
                {/* {calendarSelect === "byMonth" ? ( */}
                <div className="monthCalendar">
                  <ReactDatePicker
                    className="border-2 rounded-md mx-1 justify-end"
                    dateFormat={
                      calendarSelect === "byMonth" ? "MMMM yyyy" : "dd/MMM/yyyy"
                    }
                    showIcon
                    showMonthYearPicker={calendarSelect === "byMonth"}
                    selectsRange={calendarSelect === "byDate"}
                    startDate={startDate}
                    endDate={endDate}
                    selected={startDate}
                    onChange={handleDateChange}
                    minDate={moment()
                      .startOf("month")
                      .month(month?.label - 1)
                      .toDate()}
                    maxDate={moment()
                      .endOf("month")
                      .month(month?.label - 1)
                      .toDate()}
                    disabled={calendarSelect === "byMonth"}
                  />
                </div>
                {/* ) : (
                  <ReactDatePicker
                    className="border-2 rounded-md mx-1 justify-end"
                    dateFormat="dd/MM/yyyy"
                    minDate={minDate}
                    maxDate={maxDate}
                    showIcon
                    selected={dateSelect}
                    onChange={handleDateChange}
                  />
                )} */}
                <Tabs
                  onChange={(e) => {
                    handleCalendarSelect(e);
                  }}
                >
                  <Tabs.Segment size="sm">
                    <Tabs.Pill>By Month</Tabs.Pill>
                    <Tabs.Pill>By Date</Tabs.Pill>
                  </Tabs.Segment>
                </Tabs>
              </div>
            </div>
            {formSelect === "preferences" ? (
              <AllocatePreferences
                errors={errors}
                onChange={handleChange}
                preferences={preferences}
              />
            ) : (
              <AllocateRules
                errors={rulesErrors}
                onChange={handleRulesChange}
                rules={rules}
              />
            )}
          </div>
          <div className="p-4 bg-[#f5f5f5] border-t-2 border-beerus flex items-center justify-end gap-3">
            <Button
              onClick={closeModal}
              className="bg-transparent text-black border-[#8697A2] border rounded-lg text-sm font-semibold"
            >
              Cancel
            </Button>
            <Button
              className="bg-[#249CBA] text-white rounded-lg text-sm font-semibold"
              onClick={handleCreateSchedule}
            >
              Create Schedule
            </Button>
          </div>
        </Modal.Panel>
      </Modal>
    </>
  );
};

export default RegenerateScheduleModal;
