import { Button, Tabs } from "@heathmont/moon-core-tw";
import React from "react";
import {
  setSectionsPref,
  setSectionsRules,
} from "../../../../services/branch-admin-services/section.service";
import { deepEqual } from "../../../../utils/checkObjects";
import { ISectionPreferences } from "../../../../types/branch-admin-interfaces/IWorkPreferences.interface";
import { ISectionPreference } from "../../../../types/branch-admin-interfaces/ISectionPreference.interface";
import { store } from "../../../../store/store";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../features/ToastSlice/toastSlice";
import {
  ISectionRules,
  SectionRule,
} from "../../../../types/branch-admin-interfaces/IRulesForm.interface";
interface Props {
  prefData: ISectionPreferences | undefined;
  prefDataCopy: ISectionPreference | undefined;
  sectionRules: ISectionRules;
  sectionRulesBackup: SectionRule;
  sectionName?: string;
  revertFormData: () => void;
  validationErrors: any;
  handleGetNewData: () => void;
  formSelect: string;
}
const TitlePreferences: React.FC<Props> = ({
  prefData,
  prefDataCopy,
  sectionRules,
  sectionRulesBackup,
  sectionName,
  revertFormData,
  validationErrors,
  handleGetNewData,
  formSelect,
}) => {
  const onSaveButton = (applyToAll?: boolean) => {
    if (formSelect === "preference") {
      handlePrefFormUpdate(applyToAll);
    } else if (formSelect === "rules") handleRulesFormUpdate();
  };

  const handlePrefFormUpdate = async (applyToAll?: boolean) => {
    if (deepEqual(prefData?.preferences, prefDataCopy?.preferences)) {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("No changes in preference form"));
      return;
    }

    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    const res = await setSectionsPref(prefData, applyToAll);

    if (res?.dataUpdate) {
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Preferences updated successfully"));
      handleGetNewData();
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please fill all fields with valid data"));
    }
  };

  // const validateSectionRules = (sectionRules: ISectionRules)=> {
  //   const errors: any = {};

  //   if (sectionRules?.rules?.numberOfRooms == null || isNaN(sectionRules?.rules?.numberOfRooms )) {
  //     errors.numberOfRooms = "Number of rooms must be filled";
  //   } else if (sectionRules?.rules?.numberOfRooms < 1 || sectionRules?.rules?.numberOfRooms > 10) {
  //     errors.numberOfRooms = "There should be at least 1 room and it should not be more than 10";
  //   }

  //   // Check numberOfBeds
  //   if (sectionRules?.rules?.numberOfBeds == null || isNaN(sectionRules?.rules?.numberOfBeds)) {
  //     errors.numberOfBeds = "Number of beds must be filled";
  //   } else if (sectionRules?.rules?.numberOfBeds < 1) {
  //     errors.numberOfBeds = "There should be at least 1 bed";
  //   }

  //   // Check numberOfBedsPerNurses
  //   if (sectionRules?.rules?.numberOfBedsPerNursesDayShift == null || isNaN(sectionRules?.rules?.numberOfBedsPerNursesDayShift)) {
  //     errors.numberOfBedsPerNursesDayShift = "Number of beds per nurse (day shift) must be filled";
  //   } else if (sectionRules?.rules?.numberOfBedsPerNursesDayShift < 1 || sectionRules?.rules?.numberOfBedsPerNursesDayShift > 10) {
  //     errors.numberOfBedsPerNursesDayShift = "Number of beds per nurse (day shift) should be between 1 and 10";
  //   }

  //   if (sectionRules?.rules?.numberOfBedsPerNursesNightShift == null || isNaN( sectionRules?.rules?.numberOfBedsPerNursesNightShift)) {
  //     errors.numberOfBedsPerNursesNightShift = "Number of beds per nurse (night shift) must be filled";
  //   } else if (sectionRules?.rules?.numberOfBedsPerNursesNightShift < 1 || sectionRules?.rules?.numberOfBedsPerNursesNightShift > 10) {
  //     errors.numberOfBedsPerNursesNightShift = "Number of beds per nurse (night shift) should be between 1 and 10";
  //   }

  //   // Check numberOfBedsPerHCA
  //   if (sectionRules?.rules?.numberOfBedsPerHCADayShift == null || isNaN(sectionRules?.rules?.numberOfBedsPerHCADayShift)) {
  //     errors.numberOfBedsPerHCADayShift = "Number of beds per HCA (day shift) must be filled";
  //   } else if (sectionRules?.rules?.numberOfBedsPerHCADayShift < 1 || sectionRules?.rules?.numberOfBedsPerHCADayShift > 10) {
  //     errors.numberOfBedsPerHCADayShift = "Number of beds per HCA (day shift) should be between 1 and 10";
  //   }

  //   if (sectionRules?.rules?.numberOfBedsPerHCANightShift == null ||  isNaN(sectionRules?.rules?.numberOfBedsPerHCANightShift)) {
  //     errors.numberOfBedsPerHCANightShift = "Number of beds per HCA (night shift) must be filled";
  //   } else if (sectionRules?.rules?.numberOfBedsPerHCANightShift < 1 || sectionRules?.rules?.numberOfBedsPerHCANightShift > 10) {
  //     errors.numberOfBedsPerHCANightShift = "Number of beds per HCA (night shift) should be between 1 and 10";
  //   }

  //   // Check numberOfBedsPerSeniorNurses
  //   if (sectionRules?.rules?.numberOfBedsPerSeniorNurses == null || isNaN(sectionRules?.rules?.numberOfBedsPerSeniorNurses)) {
  //     errors.numberOfBedsPerSeniorNurses = "Number of beds per senior nurse must be filled";
  //   } else if (sectionRules?.rules?.numberOfBedsPerSeniorNurses < 1 || sectionRules?.rules?.numberOfBedsPerSeniorNurses > 10) {
  //     errors.numberOfBedsPerSeniorNurses = "Number of beds per senior nurse should be between 1 and 10";
  //   }

  //   // Check numberOfCriticalPatientsPerNurses
  //   if (sectionRules?.rules?.numberOfCriticalPatientsPerNurses == null || isNaN(sectionRules?.rules?.numberOfCriticalPatientsPerNurses)) {
  //     errors.numberOfCriticalPatientsPerNurses = "Number of critical patients per nurse must be filled";
  //   } else if (sectionRules?.rules?.numberOfCriticalPatientsPerNurses < 1 || sectionRules?.rules?.numberOfCriticalPatientsPerNurses > 10) {
  //     errors.numberOfCriticalPatientsPerNurses = "Number of critical patients per nurse should be between 1 and 10";
  //   }

  //   // Check numberOfCriticalPatientsPerHCA
  //   if (sectionRules?.rules?.numberOfCriticalPatientsPerHCA == null || isNaN(sectionRules?.rules?.numberOfCriticalPatientsPerHCA)) {
  //     errors.numberOfCriticalPatientsPerHCA = "Number of critical patients per HCA must be filled";
  //   } else if (sectionRules?.rules?.numberOfCriticalPatientsPerHCA < 1 || sectionRules?.rules?.numberOfCriticalPatientsPerHCA > 10) {
  //     errors.numberOfCriticalPatientsPerHCA = "Number of critical patients per HCA should be between 1 and 10";
  //   }

  //   return errors
  // };

  const handleRulesFormUpdate = async () => {
    if (deepEqual(sectionRules?.rules, sectionRulesBackup?.rules)) {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("No changes in rules form"));
      return;
    }

    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    const res = await setSectionsRules(sectionRules);

    if (res?.dataUpdate) {
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Rules updated successfully"));
      handleGetNewData();
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please fill all fields with valid data"));
    }
  };

  const onCancelButton = () => {
    revertFormData();
  };
  return (
    <>
      <div className="flex flex-wrap items-center p-[20px_0_0] ">
        <div className="flex-1">
          <h3 className="text-moon-20 font-semibold capitalize">
            {sectionName}{" "}
            {formSelect === "preference" ? "preferences" : "rules"}
          </h3>
          <p className="text-moon-14 text-gray-600 ">
            Set your preferences for your hospital branch according to your need
          </p>
        </div>
        <div className="inline-flex items-center gap-2">
          <Button variant="secondary" onClick={onCancelButton}>
            Cancel
          </Button>
          <Button
            variant="tertiary"
            onClick={() => onSaveButton()}
            disabled={Object.keys(validationErrors).length > 0}
          >
            Apply
          </Button>
          {formSelect === "preference" && (
            <Button
              variant="tertiary"
              onClick={() => onSaveButton(true)}
              disabled={Object.keys(validationErrors).length > 0}
            >
              Apply to all
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default TitlePreferences;
